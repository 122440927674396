import React from 'react';
import tw, { styled } from 'twin.macro';
import { Xmark } from 'iconoir-react';
import Button from '../Button';

export type Props = React.PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
}>;

const StyledOverlay = styled.div`
  ${tw`bg-dark opacity-25`}
  ${tw`fixed inset-0 z-50`}
  ${tw`transition-opacity ease-in-out duration-300`}
`;

const StyledModal = styled.div`
  ${tw`fixed z-50 flex flex-col bg-white p-4`}
  ${tw`inset-0 top-16 rounded-t-2xl`}
  ${tw`
    [@media(min-height:670px) and (min-width:640px)]:(
      w-160 rounded-b-2xl inset-auto transform
      left-1/2 -translate-x-1/2
      top-1/2 -translate-y-1/2
    )
  `}
`;

const StyledContent = styled.div`
  ${tw`
    flex flex-col gap-6 mx-4 overflow-y-scroll
    sm:(mx-14 no-scrollbar)
  `}
`;

const CloseButtonWrapper = styled.div`
  ${tw`flex justify-end mb-2`}
`;

const Header = styled.div`
  ${tw`text-center`}
`;
const Body = styled.div``;
const Footer = styled.div``;

const Modal = ({ children, isOpen, onClose }: Props) => {
  return isOpen ? (
    <>
      <StyledOverlay onClick={onClose} />
      <StyledModal>
        <CloseButtonWrapper>
          <Button onClick={onClose} shade="ghost" rightIcon={<Xmark />} />
        </CloseButtonWrapper>
        <StyledContent>{children}</StyledContent>
      </StyledModal>
    </>
  ) : null;
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
