import React from 'react';

import { Search } from 'iconoir-react';

import InputText, { Props as InputTextProps } from '../InputText';
import { ClearRightAction } from '../InputWrapper';

export type Props = Omit<InputTextProps, 'ref'> & {
  onClickClear?: () => void;
};

const SearchInput = ({ onClickClear, value, disabled, ...props }: Props) => {
  const rightEl =
    !disabled && onClickClear && value ? <ClearRightAction onClick={onClickClear} /> : undefined;

  return (
    <InputText {...props} disabled={disabled} value={value} rightEl={rightEl} icon={<Search />} />
  );
};

export default SearchInput;
