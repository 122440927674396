import React, { useCallback, useMemo, useRef, useState } from 'react';

import Confirm from '../components/Confirm';

type ConfirmContextType = {
  confirm: (data: ConfirmProps['data']) => Promise<void>;
};

type ConfirmProviderProps = {
  children: React.ReactNode;
};

const ConfirmContext = React.createContext<ConfirmContextType>({
  confirm: () => new Promise(() => {}),
});

export const ConfirmProvider = ({ children }: ConfirmProviderProps) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState<ConfirmProps['data']>('');
  const promiseResolveRef = useRef<() => void>();
  const promiseRejectRef = useRef<() => void>();

  const confirm = useCallback(
    (message: ConfirmProps['data']) =>
      new Promise<void>((resolve, reject) => {
        setConfirmOpen(true);
        setConfirmMessage(message);
        promiseResolveRef.current = resolve;
        promiseRejectRef.current = reject;
      }),
    [promiseRejectRef, promiseResolveRef, setConfirmOpen, setConfirmMessage],
  );

  const clearConfirm = useCallback(() => {
    setConfirmOpen(false);
    setConfirmMessage('');
    promiseResolveRef.current = undefined;
    promiseRejectRef.current = undefined;
  }, [promiseRejectRef, promiseResolveRef, setConfirmOpen, setConfirmMessage]);

  const resolvePromise = useCallback(() => {
    if (promiseResolveRef.current) {
      promiseResolveRef.current();
      clearConfirm();
    }
  }, [promiseResolveRef, clearConfirm]);

  const rejectPromise = useCallback(() => {
    if (promiseRejectRef.current) {
      promiseRejectRef.current();
      clearConfirm();
    }
  }, [promiseRejectRef, clearConfirm]);

  const context = useMemo(
    () => ({
      confirm,
    }),
    [confirm],
  );

  return (
    <ConfirmContext.Provider value={context}>
      {children}
      {confirmOpen && (
        <Confirm onCancel={rejectPromise} onConfirm={resolvePromise} data={confirmMessage} />
      )}
    </ConfirmContext.Provider>
  );
};

export default function useConfirm() {
  const context = React.useContext(ConfirmContext);

  if (!context) {
    throw new Error('useConfirm must be used within a ConfirmProvider');
  }

  return context;
}
