import React, { useMemo } from 'react';
import { Configure } from 'react-instantsearch';
import { replace } from '@lodash';

import { HitsTable as Hits } from '@new-components/InstantSearch';

import EmptyState from '../EmptyState';
import ErrorState from '../ErrorState';
import Hit from './Hit';
import SkeletonHit from './SkeletonHit';
import THead from './THead';

const MyExpenses = () => {
  const filters = useMemo(() => {
    const userFullName = document.querySelector('body')?.dataset.fullname;

    const singleQuoteEscaped = userFullName ? replace(userFullName, /'/g, `\\'`) : '';
    const escaped = replace(singleQuoteEscaped, /"/g, `\\"`);

    return userFullName ? `user_name:"${escaped}"` : '';
  }, []);

  return (
    <>
      <Configure filters={filters} />
      <Hits
        hitComponent={Hit}
        thead={<THead />}
        skeleton={SkeletonHit}
        emptyMessage={<EmptyState />}
        errorMessage={<ErrorState />}
      />
    </>
  );
};

export default MyExpenses;
