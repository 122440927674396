import React, { useId, useState } from 'react';
import parsePhoneNumber, { getCountryCallingCode, CountryCode } from 'libphonenumber-js';
import 'twin.macro';

import { getCountries } from '@helpers/countries';
import { DEFAULT_PHONE_COUNTRY } from '@constants';
import useForwardRef from '@hooks/useForwardRef';

import InputWrapper, { Props as InputWrapperProps } from '../InputWrapper';
import InputSelect from './InputSelect';

export type Props = Omit<React.ComponentProps<'input'>, 'size'> &
  Omit<InputWrapperProps, 'children' | 'isFocused' | 'isDisabled'> & {
    onChangeValue?: (value: string) => void;
    defaultPhoneCountry?: CountryCode;
  };

const getDefaultPhoneValue = (defaultPhoneCountry: string, defaultValue = '') => {
  const parsedDefaultValue = parsePhoneNumber(defaultValue);
  const alpha2Default = (
    parsedDefaultValue?.country ? parsedDefaultValue.country : defaultPhoneCountry
  ) as CountryCode;
  const defaultCountry = getCountries().find(c => c.alpha2 === alpha2Default);

  return {
    value: defaultCountry?.value,
    label: defaultCountry?.flag,
    countryCode: `+${getCountryCallingCode(alpha2Default)}`,
  };
};

const InputText = React.forwardRef(
  (
    {
      defaultPhoneCountry = DEFAULT_PHONE_COUNTRY,
      css,
      shade,
      size,
      error,
      hasError,
      isOCR,
      icon,
      rightEl,
      defaultValue,
      type = 'text',
      onChangeValue,
      ...props
    }: Props,
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const [countryCallingCode, setCountryCallingCode] = useState<string>(
      getDefaultPhoneValue(defaultPhoneCountry, defaultValue as string).countryCode,
    );

    const inputRef = useForwardRef<HTMLInputElement>(ref as React.RefObject<HTMLInputElement>);
    const inputId = useId();

    const focus = (event: React.MouseEvent<HTMLElement>) => {
      const targetEvent = event.target as HTMLElement;

      if (
        inputRef.current?.contains(targetEvent) ||
        targetEvent.id === `${props.id || inputId}-wrapper`
      ) {
        inputRef.current?.focus();
      }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const getValue = () => {
        if (type === 'phone' && e.target.value) return `${countryCallingCode}${e.target.value}`;
        return e.target.value;
      };

      props.onChange?.(e);
      onChangeValue?.(getValue());
    };

    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      props.onFocus?.(e);
      setIsFocused(true);
    };

    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      props.onBlur?.(e);
      setIsFocused(false);
    };

    return (
      <InputWrapper
        id={`${props.id || inputId}-wrapper`}
        css={css}
        shade={shade}
        size={size}
        hasError={hasError}
        isOCR={isOCR}
        error={error}
        isFocused={isFocused}
        isDisabled={props.disabled}
        icon={icon}
        rightEl={rightEl}
        onClick={focus}>
        <>
          {type === 'phone' && (
            <>
              <InputSelect
                labelName="flag"
                defaultValue={getDefaultPhoneValue(defaultPhoneCountry, defaultValue as string)}
                options={getCountries()}
                onChange={(v: any) => {
                  const newCountryCode = `+${getCountryCallingCode(v.alpha2)}`;

                  if (inputRef.current?.value) {
                    onChangeValue?.(`${newCountryCode}${inputRef.current?.value}`);
                  }
                  setCountryCallingCode(newCountryCode);
                }}
              />
              <div>{countryCallingCode}</div>
            </>
          )}
          <input
            ref={inputRef}
            {...props}
            autoComplete="off"
            type={type}
            defaultValue={
              type === 'phone' && defaultValue
                ? parsePhoneNumber(defaultValue as string)?.nationalNumber
                : defaultValue
            }
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </>
      </InputWrapper>
    );
  },
);

InputText.displayName = 'InputText';

export default InputText;
