import React from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { useTranslation } from 'react-i18next';
import { DateRange } from '@new-components/InstantSearch';

import { SideBar, Badge } from '@new-components';

type Props = {
  i18nKey?: string;
};

const DueAt = ({ i18nKey = 'due_at' }: Props) => {
  const { t } = useTranslation();
  const { indexUiState, indexRenderState } = useInstantSearch();
  // TODO: Remove check on `Number.MAX_SAFE_INTEGER` when useRange hook is fixed: https://github.com/algolia/instantsearch/issues/5511
  const length =
    indexUiState.range?.due_at && indexUiState.range.due_at !== `:${Number.MAX_SAFE_INTEGER}` ? 1 : 0;
  const canRefine = indexRenderState.range?.due_at?.canRefine;

  const label = t(`expenses.list.filters.${i18nKey}`);

  return (
    <SideBar.Item canOpen={canRefine}>
      <SideBar.Item.Title>
        {label}
        {length > 0 && (
          <Badge color="yellow" shape="rounded">
            {length}
          </Badge>
        )}
      </SideBar.Item.Title>
      <DateRange attribute="due_at" shade="invert" size="sm" />
    </SideBar.Item>
  );
};

export default DueAt;
