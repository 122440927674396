import React from 'react';
import tw, { styled } from 'twin.macro';

export type Props = React.ComponentProps<'div'>;
export type ItemProps = React.ComponentProps<'button'> & { shade?: 'warning' };

export const StyledSubMenu = styled.div`
  ${tw`
    items-center
    bg-white
    p-2
    rounded-t
    rounded-b-lg
    mt-1
    shadow-md-over
    z-50
  `}
`;

const SubMenu = (props: Props) => <StyledSubMenu {...props} onClick={e => e.stopPropagation()} />;

export const StyledItem = styled.button<{
  $shade?: 'warning';
}>`
  ${tw`
    flex items-center gap-2
    py-2 px-4 rounded-lg w-full bg-white
    font-semibold text-sm text-left
    hover:bg-blue-005
    focus:(bg-blue-005 text-blue-200)
    disabled:(text-blueGray-700 bg-transparent cursor-not-allowed)
  `}
  ${({ $shade }) => $shade === 'warning' && tw`text-red-700 focus:text-red-700`}

  > svg {
    ${tw`inline align-middle w-4 h-4`}
    ${({ $shade }) => ($shade === 'warning' ? tw`text-red-700` : tw`text-blue-100`)}
  }
`;

const Item = ({ shade, ...props }: ItemProps) => <StyledItem {...props} $shade={shade} />;

SubMenu.Item = Item;

export default SubMenu;
