import React from 'react';
import tw, { styled } from 'twin.macro';

export type Props = React.ComponentPropsWithoutRef<'tr'> & {
  isSelected?: boolean;
  onClick?: () => void;
};

export type StyledProps = {
  $isSelected?: boolean;
};

const StyledTr = styled.tr<StyledProps>`
  ${tw`hover:(bg-blue-005)`}
  ${({ $isSelected }) => $isSelected && tw`bg-blue-040 hover:bg-blue-040`}
  ${({ onClick }) => !!onClick && tw`cursor-pointer`}
`;

const Tr = ({ isSelected, ...props }: Props) => (
  <StyledTr {...props} $isSelected={isSelected} css={!!props.onClick && tw`cursor-pointer`} />
);

export default Tr;
