import React from 'react';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'react-i18next';

import { ErrorStateMessage } from '@new-components';

const StyledMessage = styled.div`
  ${tw`text-sm text-center space-y-2`}
`;

const ErrorState = () => {
  const { t } = useTranslation();

  return (
    <ErrorStateMessage>
      <StyledMessage>
        <h6 tw="text-blue-500 block">{t('globals.error_occurred')}</h6>
        <span tw="text-blueGray-700 block">{t('globals.error_try_again')}</span>
      </StyledMessage>
    </ErrorStateMessage>
  );
};

export default ErrorState;
