import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useStats } from 'react-instantsearch';
import tw, { styled } from 'twin.macro';
import type { AlgoliaSettings } from '@hooks';

import { useSelectedHits } from '@contexts/SelectedHits';
import {
  postExpensesBatchUpates,
  searchAnalyticCodes,
  searchBusinessCodes,
  searchExpenseCategories,
} from '@api';
import { toastify } from '@helpers/toastify';

import { Button, Select, TagWithNumber, Option } from '@new-components';
import ConfirmBulkActionsModal from './ConfirmBulkActionsModal';

export type BulkActionsForm = {
  expenseCategoryId: string;
  step: number;
  businessCodeId: string;
  billable: string;
  overwriteAnalyticCode: string;
  analyticCodeIds: string[];
};

export type Props = React.ComponentProps<'form'> & { settings: AlgoliaSettings };

const Form = styled.form`
  ${tw`
  flex flex-col justify-center gap-y-4
  py-6 px-4
  font-semibold text-xs
`}
`;

const loadOptions =
  <T,>(
    api: (params?: ApiParams) => Promise<PaginatedSearchRequest<T>>,
    defaultParams: ApiParams = {},
  ) =>
  async (query: string, _prev: any, opts?: { page: number }) => {
    const page = opts?.page || 1;

    const x = Object.keys(defaultParams)[0];
    const params = { ...defaultParams, page, skip_session: true } as ApiParams;

    params[x] = { ...(params[x] || {}), multifield_search: query };

    try {
      const res = await api(params);
      const data = res.data.results;

      return {
        options: data.map((item: any) => ({ value: item.id, label: item.name })),
        hasMore: data.length > 0,
        additional: { page: page + 1 },
      };
    } catch (error) {
      return { options: [], hasMore: false };
    }
  };

const BulkActions = ({ settings, ...props }: Props) => {
  const { t } = useTranslation();
  const { ids, selectMany, all } = useSelectedHits();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isDirty, isValid },
  } = useForm<BulkActionsForm>({
    mode: 'all',
    defaultValues: {
      expenseCategoryId: undefined,
      step: undefined,
      businessCodeId: undefined,
      billable: undefined,
      overwriteAnalyticCode: undefined,
      analyticCodeIds: [],
    },
  });
  const { nbHits } = useStats();
  const nbAll = nbHits > 1000 ? '1000+' : nbHits;

  const onConfirmSubmit = async (values: any) => {
    const params = {
      ids: all ? [] : ids,
      all,
      attributes: {
        expenseCategoryId: values.expenseCategoryId,
        step: values.step,
        businessCodeId: values.businessCodeId,
        billable: values.billable,
        overwriteAnalyticCode: values.overwriteAnalyticCode,
        analyticCodeIds: values.analyticCodeIds,
      },
    };

    try {
      postExpensesBatchUpates(params).then(response => {
        if (response.success) {
          toastify('success', t('expenses.bulk.success'), { autoClose: 3000, theme: 'colored' });
          selectMany([]);
        }
      });
    } catch (err: any) {
      const response = JSON.parse(err.request.responseText);
      if (response.error) toastify('error', response.error, { autoClose: false, theme: 'colored' });
    }

    setIsConfirmModalOpen(false);
  };

  const onSubmit = () => setIsConfirmModalOpen(true);

  // TODO: Create a controlled component : https://mooncard.atlassian.net/browse/MOON-1838
  return (
    <>
      <Form {...props}>
        <TagWithNumber value={all ? nbAll : ids.length}>
          {t('expenses.bulk.selected_expenses')}
        </TagWithNumber>
        <Controller
          name="expenseCategoryId"
          control={control}
          render={({ field: { onChange, name } }) => (
            <div>
              <label htmlFor="select_expense_categories" tw="block mb-1">
                {t('expenses.bulk.edit_expense_categorie')}
              </label>
              <Select
                async
                name={name}
                additional={{ page: 1 }}
                loadOptions={loadOptions(searchExpenseCategories, {
                  expense_category_search: { archived_at_null: true },
                })}
                defaultOptions
                onChange={option => onChange((option as Option)?.value)}
                isClearable
              />
            </div>
          )}
        />
        <Controller
          name="step"
          control={control}
          render={({ field: { onChange, name } }) => (
            <div>
              <label htmlFor="select_step" tw="block mb-1">
                {t('expenses.bulk.edit_step')}
              </label>
              <Select
                name={name}
                options={[
                  { label: t('expenses.attributes.step.paid'), value: 0 },
                  { label: t('expenses.attributes.step.verified'), value: 1 },
                  { label: t('expenses.attributes.step.controlled'), value: 2 },
                  { label: t('expenses.attributes.step.approved'), value: 3 },
                ]}
                onChange={option => onChange((option as Option)?.value)}
                isClearable
              />
            </div>
          )}
        />
        {settings.displayBulkBusinessCodes && (
          <Controller
            name="businessCodeId"
            control={control}
            render={({ field: { onChange, name } }) => (
              <div>
                <label htmlFor="select_business_codes" tw="block mb-1">
                  {t('expenses.bulk.edit_business_code')}
                </label>
                <Select
                  async
                  name={name}
                  loadOptions={loadOptions(searchBusinessCodes, {
                    business_code_search: { archived_at_null: true },
                  })}
                  defaultOptions
                  onChange={option => onChange((option as Option)?.value)}
                  isClearable
                />
              </div>
            )}
          />
        )}
        <Controller
          name="billable"
          control={control}
          render={({ field: { onChange, name } }) => (
            <div>
              <label htmlFor="select_billable" tw="block mb-1">
                {t('expenses.bulk.edit_billable')}
              </label>
              <Select
                name={name}
                options={[
                  { label: t('globals.yes'), value: 'true' },
                  { label: t('globals.no'), value: 'false' },
                ]}
                onChange={option => onChange((option as Option)?.value)}
                isClearable
              />
            </div>
          )}
        />
        {settings.displayBulkAnalyticCodes && (
          <div>
            <label htmlFor="select_overwrite_analytic_code" tw="block mb-1">
              {t('expenses.bulk.edit_analytic_codes')}
            </label>

            <Controller
              name="overwriteAnalyticCode"
              control={control}
              render={({ field: { onChange, name } }) => (
                <Select
                  name={name}
                  options={[
                    { label: t('globals.add'), value: 'false' },
                    { label: t('globals.overwrite'), value: 'true' },
                  ]}
                  onChange={option => onChange((option as Option)?.value)}
                  isClearable
                  tw="mb-1"
                />
              )}
            />

            <Controller
              name="analyticCodeIds"
              control={control}
              rules={{
                validate: value => {
                  const overwrite = getValues('overwriteAnalyticCode') || false;
                  return (!overwrite && value.length === 0) || (overwrite && value.length > 0);
                },
              }}
              render={({ field: { onChange, name } }) => (
                <Select
                  async
                  name={name}
                  placeholder={t('expenses.bulk.edit_analytic_codes')}
                  isMulti
                  loadOptions={loadOptions(searchAnalyticCodes, {
                    analytic_code_search: { archived_at_null: true },
                  })}
                  defaultOptions
                  onChange={newValues =>
                    onChange((newValues as Option[]).map(({ value }) => value))
                  }
                />
              )}
            />
          </div>
        )}

        <Button tw="justify-center mt-4" onClick={onSubmit} disabled={!isValid || !isDirty}>
          {t('globals.apply')}
        </Button>
      </Form>

      <ConfirmBulkActionsModal
        isOpen={isConfirmModalOpen}
        onAccept={handleSubmit(onConfirmSubmit)}
        onClose={() => setIsConfirmModalOpen(false)}
      />
    </>
  );
};

export default BulkActions;
