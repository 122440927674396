import React from 'react';
import { DefaultTheme } from 'styled-components';
import { MainContainer, ThemeButton } from './styled-components';

const DevThemeSwitcher = ({ theme, themes, setTheme }: DevThemeSwitcherProps) => {
  return (
    <MainContainer>
      {Object.values(themes).map((selectedTheme: DefaultTheme) => (
        <ThemeButton
          key={selectedTheme.name}
          $color={selectedTheme.components.button.primary.default.background}
          $selected={selectedTheme.name === theme.name}
          onClick={() => setTheme(selectedTheme)}>
          {selectedTheme.name}
        </ThemeButton>
      ))}
    </MainContainer>
  );
};

export default DevThemeSwitcher;
