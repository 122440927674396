import { snakeize } from '@helpers/object';
import { apiClient } from '../constants';

export const postCurrentAlgoliaSearch = (uiState: any) =>
  apiClient.post('/current_algolia_search', { filters: snakeize(uiState) });

export const postSiphonParamsToAlgoliaAttributes = (searchParams: URLSearchParams) =>
  apiClient.post('/siphon_to_algolia', searchParams).then(({ data }) => data);

export const postExpensesBatchUpates = (params: any) =>
  apiClient.post('/expenses/batch_updates', snakeize(params)).then(({ data }) => data);
