import React from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { useTranslation } from 'react-i18next';
import { RefinementList } from '@new-components/InstantSearch';

import { SideBar, Badge } from '@new-components';

const Complete = () => {
  const { t } = useTranslation();
  const { indexUiState, indexRenderState } = useInstantSearch();
  const length = indexUiState.refinementList?.complete?.length || 0;
  const canRefine = indexRenderState.refinementList?.complete?.canRefine || false;

  return (
    <SideBar.Item canOpen={canRefine}>
      <SideBar.Item.Title>
        {t('expenses.list.filters.complete')}
        {length > 0 && (
          <Badge color="yellow" shape="rounded">
            {length}
          </Badge>
        )}
      </SideBar.Item.Title>
      <RefinementList
        attribute="complete"
        sortBy={['name:desc']}
        i18nKeyPrefix="expenses.list.filters.boolean"
      />
    </SideBar.Item>
  );
};

export default Complete;
