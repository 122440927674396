import React from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import 'twin.macro';

import { Tabs } from '@new-components';

export type Props = {
  current: string;
  tabs: string[];
};

const ExpenseTabs = ({ current, tabs }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { uiState } = useInstantSearch();

  return (
    <Tabs>
      {tabs.map(tab => {
        const { configure, ...params } = uiState[tab] || {};

        return (
          <Tabs.Tab
            key={tab}
            isActive={tab === current}
            onClick={() => navigate(`/expenses/${tab}?${qs.stringify({ [tab]: params })}`)}>
            {t(`expenses.tabs.${tab}`)}
          </Tabs.Tab>
        );
      })}
    </Tabs>
  );
};

export default ExpenseTabs;
