import React from 'react';
import tw, { styled } from 'twin.macro';

export type Props = React.ComponentPropsWithoutRef<'div'> & {
  selected?: boolean;
  onClick?: () => void;
};

const CardWrapper = styled.div<{ $selected: boolean }>`
  ${tw`block w-full p-4 items-center rounded-lg cursor-pointer`}
  ${tw`border border-blueGray-100`}
  ${tw`font-heading font-semibold text-sm text-primary`}
  ${tw`
    hover:(bg-blue-005 border-blue-100)
    active:(bg-blue-010 border-blue-100)
  `}
  ${({ $selected }) => $selected && tw`border-blue-100 bg-blue-005`}
`;

const IconWrapper = styled.div`
  ${tw`
    flex justify-center
    text-blue-100
    pb-3
    [>svg]:(w-8 h-8)
  `}
`;

const Card = ({ children, selected = false, onClick, ...props }: Props) => (
  <CardWrapper $selected={selected} onClick={onClick} {...props}>
    {children}
  </CardWrapper>
);

Card.Icon = IconWrapper;

export default Card;
