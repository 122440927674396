import React from 'react';
import tw, { styled } from 'twin.macro';

import { useTranslation } from 'react-i18next';
import { useStats } from 'react-instantsearch';
import { useDebounce } from 'use-debounce';

type Props = {
  i18nKey: string;
};

const StyledStats = styled.span`
  ${tw`text-sm leading-6 font-semibold whitespace-nowrap`}
`;

const Stats = ({ i18nKey }: Props) => {
  const { t } = useTranslation();
  const { nbHits } = useStats();
  const [count] = useDebounce(nbHits, 150);

  return (
    <StyledStats>
      {count > 1000 ? t('expenses.list.thousand_results') : t(i18nKey, { count })}
    </StyledStats>
  );
};

export default Stats;
