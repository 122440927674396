import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRange, UseRangeProps } from 'react-instantsearch';
import 'twin.macro';

import { swap } from '@helpers/array';
import { InputText } from '@new-components';

type Range = [number | undefined, number | undefined];
type Props = UseRangeProps &
  Omit<React.ComponentProps<typeof InputText>, 'max' | 'min' | 'value'> & {
    multiplier?: number;
  };

const NumberRange = ({
  id,
  rightEl,
  multiplier = 1,
  max = Number.MAX_SAFE_INTEGER,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { start: range, refine } = useRange({ max, ...props });

  const [value, setValue] = useState<[string, string]>([
    range[0] && Number.isFinite(range[0]) ? (range[0] / multiplier).toString() : '',
    range[1] && range[1] < Number.MAX_SAFE_INTEGER ? (range[1] / multiplier).toString() : '',
  ]);

  const onChange = (i: 0 | 1) => (v: string) => {
    const newValue = [...value] as [string, string];
    const newRange = [
      range[0] && Number.isFinite(range[0]) ? range[0] : undefined,
      range[1] && range[1] < Number.MAX_SAFE_INTEGER ? range[1] : undefined,
    ] as Range;

    newValue[i] = v.replace(',', '.');
    newRange[i] = newValue[i] ? parseFloat(newValue[i]) * multiplier : undefined;

    refine(newRange);
    setValue(newValue);
  };

  const onBlur = () => {
    if (range[0] && range[1] && range[0] > range[1]) {
      refine(swap(range) as Range);
      setValue(swap(value) as [string, string]);
    }
  };

  useEffect(() => {
    setValue([
      range[0] && Number.isFinite(range[0]) ? (range[0] / multiplier).toString() : '',
      range[1] && range[1] < Number.MAX_SAFE_INTEGER ? (range[1] / multiplier).toString() : '',
    ]);
  }, [range, multiplier]);

  return (
    <div tw="flex flex-row gap-1 items-center">
      <div>
        <label htmlFor={`${id}-min`} tw="block mb-1">
          {t('globals.min')}
        </label>
        <InputText
          id={`${id}-min`}
          type="text"
          placeholder={t('globals.min')}
          pattern="[1-9][0-9]*([.][0-9]?)?"
          value={value[0]}
          onChangeValue={onChange(0)}
          onBlur={onBlur}
          rightEl={rightEl}
        />
      </div>
      <div>
        <label htmlFor={`${id}-max`} tw="block mb-1">
          {t('globals.max')}
        </label>
        <InputText
          id={`${id}-max`}
          type="text"
          placeholder={t('globals.max')}
          pattern="[1-9][0-9]*([.][0-9]?)?"
          value={value[1]}
          onChangeValue={onChange(1)}
          onBlur={onBlur}
          rightEl={rightEl}
        />
      </div>
    </div>
  );
};

export default NumberRange;
