import React from 'react';
import 'twin.macro';

import { Skeleton, Tr } from '@new-components';

const SkeletonHit = () => (
  <Tr>
    <td tw="w-12 p-1.5">
      <Skeleton tw="w-4 h-4 ml-1" />
    </td>
    <td tw="w-28">
      <Skeleton />
    </td>
    <td tw="w-36">
      <Skeleton tw="h-5" />
    </td>
    <td tw="w-36">
      <Skeleton />
    </td>
    <td tw="w-52">
      <Skeleton />
    </td>
    <td tw="w-36">
      <Skeleton />
    </td>
    <td tw="w-24">
      <Skeleton />
    </td>
    <td tw="w-24">
      <Skeleton tw="h-5" />
    </td>
    <td tw="w-24">
      <Skeleton tw="h-5" />
    </td>
    <td tw="w-24">
      <Skeleton />
    </td>
  </Tr>
);

export default SkeletonHit;
