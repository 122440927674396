import { apiClient } from '../constants';

export const searchExpenseCategories = (params?: ApiParams) =>
  apiClient.post<PaginatedSearchRequest<ExpenseCategory>, PaginatedSearchRequest<ExpenseCategory>>(
    '/expense_categories/search',
    params,
  );

export const getExpenseCategoriesSearch = () =>
  apiClient.get<PaginatedSearchRequest<ExpenseCategory>, PaginatedSearchRequest<ExpenseCategory>>(
    '/expense_categories/search',
  );

export const deleteExpenseCategoriesSearch = () =>
  apiClient.delete<
    PaginatedSearchRequest<ExpenseCategory>,
    PaginatedSearchRequest<ExpenseCategory>
  >('/expense_categories/search');

export const deleteExpenseCategory = (id: ExpenseCategory['id']) =>
  apiClient.delete(`/expense_categories/${id}.json`);

export const archiveExpenseCategory = (id: ExpenseCategory['id']) =>
  apiClient.post(`/expense_categories/${id}/toggle_archive.json`);

export const addExpenseCategory = (params: ExpenseCategoryForm) =>
  apiClient.post<ExpenseCategory, ExpenseCategory>('/expense_categories', params);

export const updateExpenseCategory = (id: string, params: ExpenseCategoryForm) =>
  apiClient.patch<ExpenseCategory, ExpenseCategory>(`/expense_categories/${id}.json`, params);

export const getExpenseCategory = (id?: ExpenseCategory['id']) =>
  apiClient.get(`/expense_categories/${id}.json`);

export const getExpenseCategoryTemplates = () => apiClient.get('/expense_categories/templates');

export const getExpenseCategoryNationalVehicleTypes = () =>
  apiClient.get('/expense_categories/national_vehicle_types');

export const getExpenseCategoryVehicleFuelTypes = () =>
  apiClient.get('/expense_categories/vehicle_fuel_types');

export const getExpenseCategoryDepartments = () =>
  apiClient.get('/expense_categories/departments').then(response => response.data);

export const importExpenseCategoriesFile = (formData: FormData) =>
  apiClient.post('/expense_categories/import', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
