import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckCircleSolid, Download, DownloadCircle } from 'iconoir-react';
import 'twin.macro';

import { getCSRFToken } from '@constants/api-client';
import { Modal, ModalProps, RadioButtonCard, Button, BadgeIcon } from '@new-components';

const ExportReceiptsModal = ({ isOpen, onClose }: ModalProps) => {
  const { t } = useTranslation();
  const [type, setType] = useState<'receipts' | 'pdf'>('receipts');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitted) e.preventDefault();
    setIsSubmitted(true);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form method="post" action="/expense_exports?restore_page=1" onSubmit={onSubmit}>
        <input type="hidden" name="authenticity_token" value={getCSRFToken()} />
        <input type="hidden" value="employee" name="expense_export[group_by]" />
        <Modal.Header>
          <BadgeIcon size="lg" color="blue">
            <Download tw="w-8 h-8 text-blue-500 m-auto" />
          </BadgeIcon>
          <h3 tw="text-primary mt-2">{t('expenses.export_receipts_modal.title')}</h3>
        </Modal.Header>
        <hr tw="my-6" />
        <Modal.Body>
          <div tw="mb-6 space-y-4">
            <RadioButtonCard
              name="expense_export[type]"
              value="receipts"
              icon={<CheckCircleSolid />}
              onChange={() => setType('receipts')}
              selected={type === 'receipts'}>
              {t('expenses.export_receipts_modal.types.receipts')}
            </RadioButtonCard>
            <RadioButtonCard
              value="pdf"
              name="expense_export[type]"
              icon={<CheckCircleSolid />}
              onChange={() => setType('pdf')}
              selected={type === 'pdf'}>
              {t('expenses.export_receipts_modal.types.pdf')}
            </RadioButtonCard>
          </div>
        </Modal.Body>
        <Modal.Footer tw="py-4 flex">
          <Button
            type="submit"
            rightIcon={<DownloadCircle />}
            tw="w-full justify-center"
            disabled={isSubmitted}>
            {t('globals.export')}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ExportReceiptsModal;
