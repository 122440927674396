import React from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { useTranslation } from 'react-i18next';

import { SideBar, Badge } from '@new-components';
import { ToggleRefinement } from '@new-components/InstantSearch';

const ToComplete = () => {
  const { t } = useTranslation();
  const { indexRenderState } = useInstantSearch();
  const nb = indexRenderState.toggleRefinement?.complete?.value.onFacetValue.count || 0;
  const canRefine = indexRenderState.toggleRefinement?.complete?.canRefine;

  const label = t('expenses.list.filters.to_complete');

  return (
    <SideBar.Item canOpen={canRefine}>
      <SideBar.Item.Title
        rightEl={<ToggleRefinement attribute="complete" label={label} on={false} />}>
        {label}
        <Badge color="red" shape="square">
          {nb}
        </Badge>
      </SideBar.Item.Title>
    </SideBar.Item>
  );
};

export default ToComplete;
