import { Check, InfoCircle, WarningCircle, Xmark, Sparks, Archive } from 'iconoir-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';

export type Variant = 'info' | 'warning' | 'error' | 'success' | 'ai' | 'archived';
export type Size = 'lg' | 'md' | 'sm';

type StyledProps = {
  $variant: Variant;
  $size: Size;
};

export type Props = React.PropsWithChildren<{
  variant: Variant;
  size?: Size;
  icon?: React.ReactElement;
}>;

const icons = {
  info: <InfoCircle />,
  warning: <WarningCircle />,
  error: <Xmark />,
  success: <Check />,
  ai: <Sparks />,
  archived: <Archive />,
};

const bgColor = {
  info: tw`bg-blue-005`,
  warning: tw`bg-orange-050`,
  error: tw`bg-red-050`,
  success: tw`bg-green-005`,
  ai: tw`bg-purple-050`,
  archived: tw`bg-gray-100`,
};

const iconColor = {
  info: tw`text-blue-100`,
  warning: tw`text-orange-700`,
  error: tw`text-red-700`,
  success: tw`text-green-700`,
  ai: tw`text-purple-700`,
  archived: tw`text-gray-600`,
};

const sizes = {
  sm: tw`p-1 text-xxs leading-5 gap-1`,
  md: tw`p-2 text-xs gap-2`,
  lg: tw`p-2 text-sm gap-2`,
};

const StyledMessage = styled.div<StyledProps>`
  ${tw`flex items-center rounded-lg [>strong]:font-semibold`}
  ${({ $variant }) => bgColor[$variant]}
  ${({ $size }) => sizes[$size]}
`;

const StyledIconContainer = styled.div<{ $variant: Variant }>`
  ${tw`
      bg-white
      p-0.5
      rounded-full
    `}

  > svg {
    ${tw`w-4 h-4`}
    ${({ $variant }) => iconColor[$variant]}
  }
`;

const Message = ({ variant = 'info', size = 'md', children, icon, ...props }: Props) => {
  const i = icon || icons[variant] || false;
  const { t } = useTranslation();

  return (
    <StyledMessage {...props} $variant={variant} $size={size}>
      {!!i && <StyledIconContainer $variant={variant}>{i}</StyledIconContainer>}
      <strong>{t(`globals.${variant}`)}</strong>
      {children}
    </StyledMessage>
  );
};

export default Message;
