import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getPermissions } from '@api';

const usePermissions = (abilities: string[] = []) => {
  const abilityParams = abilities.join(',');
  const { data } = useQuery(['permissions', abilities], () => getPermissions(abilityParams));

  const can = useCallback(
    (ability: string) => {
      const key = `can_${ability.trim()}`;
      if (!data) {
        return false;
      }
      const fetchAbilities = data.data;
      return fetchAbilities[key];
    },
    [data],
  );

  return { can };
};

export default usePermissions;
