import tw, { styled } from 'twin.macro';

export type Color =
  | 'white'
  | 'gray'
  | 'dark-blue'
  | 'light-blue'
  | 'dark-blue-2'
  | 'yellow'
  | 'red'
  | 'green';

export type Shape = 'oval' | 'square' | 'rounded';

const colors = {
  white: tw`text-blue-500 bg-white`,
  gray: tw`text-blueGray-700 bg-white`,
  'dark-blue': tw`text-white bg-blue-500`,
  'light-blue': tw`text-blue-100 bg-blue-005`,
  'dark-blue-2': tw`text-blue-500 bg-blue-005`,
  yellow: tw`text-blue-500 bg-yellow-050`,
  red: tw`text-red-700 bg-red-050`,
  green: tw`text-green-500 bg-green-050`,
};

const shapes = {
  oval: tw`py-0.5 px-1 rounded-full`,
  square: tw`py-0.5 px-1 rounded-sm`,
  rounded: tw`py-1 px-[7px] flex items-center justify-center rounded-full`,
};

const Badge = styled.span<{ color: Color; shape: Shape }>`
  ${tw`font-bold cursor-default`}
  ${tw`text-xxs leading-3`}
  ${tw`inline-flex justify-center min-w-4`}
  ${({ color }) => colors[color]}
  ${({ shape }) => shapes[shape]}
`;

export default Badge;
