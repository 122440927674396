import tw, { styled } from 'twin.macro';

export type Color =
  | 'grey'
  | 'dark-blue'
  | 'medium-blue'
  | 'light-blue'
  | 'turquoise'
  | 'green'
  | 'yellow'
  | 'orange'
  | 'red'
  | 'pink'
  | 'purple';

const colors = {
  grey: tw`text-blueGray-800 bg-gray-100`,
  'dark-blue': tw`text-blue-500 bg-blueGray-050`,
  'medium-blue': tw`text-blue-500 bg-blue-040`,
  'light-blue': tw`text-blue-100 bg-blue-005`,
  turquoise: tw`text-turquoise-700 bg-turquoise-050`,
  green: tw`text-green-700 bg-green-050`,
  yellow: tw`text-primary bg-yellow-050`,
  orange: tw`text-orange-700 bg-orange-050`,
  red: tw`text-red-700 bg-red-050`,
  pink: tw`text-pink-700 bg-pink-050`,
  purple: tw`text-purple-700 bg-purple-050`,
};

const Tag = styled.span<{ color: Color }>`
  ${tw`
    inline-flex justify-center items-center
    py-0.75 px-2
    font-semibold text-xs leading-3.5
    rounded
  `}
  ${({ color }) => colors[color]}
`;

export default Tag;
