import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';

import { ConfirmProvider } from '../../hooks/useConfirm';

import Toastify from '../../styles/toastify';
import DatepickerStyle from '../../styles/datepicker-style';
import GlobalStyles from '../../styles/GlobalStyles';
import ReactLoadingSkeleton from '../../styles/react-loading-skeleton';

import * as themes from '../../themes';

import DevThemeSwitcher from '../DevThemeSwitcher';
import { ContentContainer, MainContainer } from './styled-components';

const Layout = () => {
  const [theme, setTheme] = useState(themes.production);
  const isDev = useMemo(() => window.location.hostname === 'localhost', []);
  const isSandbox = useMemo(
    () =>
      window.location.hostname.includes('sandbox') || window.location.hostname.includes('review'),
    [],
  );
  const isStaging = useMemo(() => window.location.hostname.includes('staging'), []);
  const isProd = useMemo(() => !isDev && !isSandbox && !isStaging, [isDev, isSandbox, isStaging]);

  useEffect(() => {
    if (isDev) {
      setTheme(themes.development);
    } else if (isSandbox) {
      setTheme(themes.sandbox);
    } else if (isStaging) {
      setTheme(themes.staging);
    } else {
      setTheme(themes.production);
    }
  }, [setTheme, isDev, isStaging, isSandbox, isProd]);

  useLayoutEffect(() => {
    const flashes = document.querySelectorAll('.toast') as NodeListOf<HTMLElement>;

    flashes.forEach((flash: HTMLElement) => {
      const classList = Array.from(flash.classList.values());
      const type = classList.find(c => c.includes('flash-'))?.split('-')[1] || 'info';
      switch (type) {
        case 'info':
          toast.info(flash.innerText);
          break;
        case 'success':
          toast.success(flash.innerText);
          break;
        case 'error':
          toast.error(flash.innerText);
          break;
        case 'warning':
          toast.warning(flash.innerText);
          break;
        default:
          toast.info(flash.innerText);
          break;
      }
      flash.remove();
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <GlobalStyles />
        <DatepickerStyle />
        <Toastify />
        <ReactLoadingSkeleton />
        <MainContainer>
          <ContentContainer>
            <Outlet />
          </ContentContainer>
        </MainContainer>
        <ToastContainer closeButton />
      </ConfirmProvider>
      {(isSandbox || isDev) && (
        <DevThemeSwitcher theme={theme} themes={themes} setTheme={setTheme} />
      )}
    </ThemeProvider>
  );
};

export default Layout;
