import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { InstantSearch, useInstantSearch } from 'react-instantsearch';
import algoliasearch from 'algoliasearch/lite';
import 'twin.macro';

import type { AlgoliaSettings } from '@hooks';
import { EXPENSES_PATH_TABS } from '@constants';
import { useAlgoliaSettings, useSiphonToAlgoliaQuery } from '@hooks';
import SelectedHitsProvider from '@contexts/SelectedHits';

import type { ExpensesPathTabs } from './types';
import DropdownAdd from './DropdownAdd';
import ExpensesSearch from './ExpensesSearch';
import Tabs from './Tabs';

type IndexUiState = {
  query?: string;
  range?: { [attribute: string]: any };
  refinementList?: { [attribute: string]: any };
  toggle?: { [attribute: string]: any };
  page?: number;
  hitsPerPage?: number;
};

const toggleAttributes = ['type'];

const rangeAttributes = ['amount', 'date', 'due_at'];

const refinementListAttributes = [
  'card_token',
  'user_name',
  'expense_category',
  'receipt',
  'step',
  'complete',
  'department_name',
  'accountability',
  'accessible_by',
  'billable',
  'business_code',
  '_tags',
  'merchant_name',
  'location',
  'settled',
  'driver_code',
  'license_plate',
  'source_type',
  'reimbursement_id',
];

const paramsToIndexUiState = (params?: Record<string, any>): IndexUiState => {
  const state: IndexUiState = {
    range: {},
    refinementList: {},
    toggle: {},
    page: 1,
    hitsPerPage: 25,
  };

  toggleAttributes.forEach(key => {
    if (params?.[key] && state.toggle) state.toggle[key] = params[key];
  });

  rangeAttributes.forEach(key => {
    if (params?.[key] && state.range) state.range[key] = params[key];
  });

  refinementListAttributes.forEach(key => {
    if (params?.[key] && state.refinementList) state.refinementList[key] = params[key];
  });

  if (params?.page) state.page = params.page;
  if (params?.hits_per_page) state.hitsPerPage = params.hits_per_page;
  // eslint-disable-next-line prefer-destructuring
  if (params?.query?.[0]) state.query = params.query[0];

  return state;
};

const IndexSearchProvider = ({
  indexId,
  children,
}: React.PropsWithChildren<{ indexId: ExpensesPathTabs }>) => {
  const { indexUiState, setIndexUiState } = useInstantSearch();
  const [prev, setPrev] = useState(indexId);
  const [uiState, setUiState] = useState<{ [K in ExpensesPathTabs]: {} }>({
    all: {},
    card: {},
    mine: {},
    external: {},
    employee: {},
    km: {},
    supplier: {},
  });

  useEffect(() => {
    if (prev !== indexId) {
      setPrev(indexId);
      setIndexUiState(uiState[indexId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexId]);

  useEffect(() => {
    setUiState({ ...uiState, [indexId]: indexUiState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexId, indexUiState]);

  return children;
};

const TabsAndSearch = ({ settings }: { settings: AlgoliaSettings }) => {
  const navigate = useNavigate();
  const match = useMatch('/expenses/:slug');

  const slug = (match?.params.slug as ExpensesPathTabs) || 'all';

  const tabs = EXPENSES_PATH_TABS.filter(t => settings.displayTabs?.includes(t));

  if (!tabs.includes(slug)) {
    setTimeout(() => navigate('/expenses/mine'), 250);
    return null;
  }

  return (
    <>
      <Tabs current={slug} tabs={tabs} />

      <IndexSearchProvider indexId={slug}>
        <SelectedHitsProvider indexId={slug}>
          <ExpensesSearch indexId={slug} settings={settings} />
        </SelectedHitsProvider>
      </IndexSearchProvider>
    </>
  );
};

const ExpensesLayout = () => {
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(document.location.search);

  const { data: algolia } = useAlgoliaSettings();
  const { data: params, isFetching } = useSiphonToAlgoliaQuery(searchParams);

  if (!algolia || isFetching) return null;

  const searchClient = algoliasearch(algolia.appId, algolia.appKey);

  return (
    <>
      <div tw="flex items-center justify-between px-8 py-4">
        <h3>{t('expenses.list.title')}</h3>
        <DropdownAdd />
      </div>
      <InstantSearch
        indexName={algolia.indexName}
        searchClient={searchClient}
        initialUiState={{ [algolia.indexName]: paramsToIndexUiState(params) }}
        routing>
        <TabsAndSearch settings={algolia} />
      </InstantSearch>
    </>
  );
};

export default ExpensesLayout;
