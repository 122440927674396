import React, { useState } from 'react';
import { useStats } from 'react-instantsearch';
import { useTranslation } from 'react-i18next';
import { NavArrowDown, PageFlip, ViewColumns3 } from 'iconoir-react';
import { Dropdown } from '@new-components';

import ExportReceiptsModal from './ExportReceiptsModal';
import ExportExpenseModal from './ExportExpenseModal';

const DropdownExport = () => {
  const { t } = useTranslation();
  const { nbHits } = useStats();
  const [openedModal, setOpenedModal] = useState<'models' | 'receipts' | null>(null);

  const onClick = (value: 'models' | 'receipts' | null) => {
    setOpenedModal(value);
  };

  return (
    <>
      <Dropdown
        alignement="right"
        button={
          <Dropdown.Button
            shade="tertiary"
            size="md"
            rightIcon={<NavArrowDown />}
            disabled={nbHits === 0}>
            {t('globals.export')}
          </Dropdown.Button>
        }>
        <Dropdown.SubMenu>
          <Dropdown.SubMenu.Item onClick={() => onClick('models')}>
            <ViewColumns3 />
            <span>{t('expenses.list.export_use_model')}</span>
          </Dropdown.SubMenu.Item>
          <Dropdown.SubMenu.Item onClick={() => onClick('receipts')}>
            <PageFlip />
            <span>{t('expenses.list.export_receipts')}</span>
          </Dropdown.SubMenu.Item>
          <Dropdown.SubMenu.Item
            onClick={() => {
              window.location.href = '/expenses/exports';
            }}>
            <PageFlip />
            <span>{t('expenses.list.expense_exports')}</span>
          </Dropdown.SubMenu.Item>
        </Dropdown.SubMenu>
      </Dropdown>

      <ExportExpenseModal isOpen={openedModal === 'models'} onClose={() => setOpenedModal(null)} />
      <ExportReceiptsModal
        isOpen={openedModal === 'receipts'}
        onClose={() => setOpenedModal(null)}
      />
    </>
  );
};

export default DropdownExport;
