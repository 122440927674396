import React from 'react';
import tw, { styled } from 'twin.macro';
import { NavArrowLeft, NavArrowRight } from 'iconoir-react';

export type Shade = 'default' | 'invert' | 'primary';

type StyledProps = {
  $shade: Shade;
};

export type Props = {
  shade?: Shade;
  disabled?: 'next' | 'prev' | 'both';
  onClickNext?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPrev?: React.MouseEventHandler<HTMLButtonElement>;
};

const colors = {
  default: tw`bg-white text-primary disabled:text-blueGray-300`,
  invert: tw`bg-white border-blue-700 text-blue-700 disabled:(text-blueGray-300 bg-white/10)`,
  primary: tw`bg-blue-500 border-white text-white disabled:(text-blueGray-300 bg-blueGray-050)`,
};

const StyledButton = styled.button<StyledProps>`
  ${tw`flex items-center p-2`}
  ${tw`border border-solid border-blueGray-100 border-r-0`}
  ${tw`first:(rounded-l-lg)`}
  ${tw`last:(rounded-r-lg border-l border-r)`}
  ${tw`[> svg]:(w-4 h-4 stroke-2)`}
  ${({ $shade }) => colors[$shade]}
`;

const PrevNextButtonGroup = ({ shade = 'default', disabled, onClickNext, onClickPrev }: Props) => (
  <div tw="flex">
    <StyledButton
      $shade={shade}
      disabled={disabled === 'prev' || disabled === 'both'}
      onClick={onClickPrev}
      aria-label="Previous">
      <NavArrowLeft />
    </StyledButton>
    <StyledButton
      $shade={shade}
      disabled={disabled === 'next' || disabled === 'both'}
      onClick={onClickNext}
      aria-label="Next">
      <NavArrowRight />
    </StyledButton>
  </div>
);

export default PrevNextButtonGroup;
