import React from 'react';
import { Configure } from 'react-instantsearch';

import { HitsTable as Hits } from '@new-components/InstantSearch';

import EmptyState from '../EmptyState';
import ErrorState from '../ErrorState';
import Hit from '../Hit';
import SkeletonHit from '../SkeletonHit';
import THead from '../THead';

const CardExpenses = () => (
  <>
    <Configure filters="source_type:CardExpense" />
    <Hits
      hitComponent={Hit}
      thead={<THead />}
      skeleton={SkeletonHit}
      emptyMessage={<EmptyState />}
      errorMessage={<ErrorState />}
    />
  </>
);

export default CardExpenses;
