import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button as StyledButton } from './styled-components';

export default function Button({
  children,
  disabled = false,
  href,
  filter = false,
  icon,
  onClick,
  outline = false,
  variant = 'primary',
  size = 'm',
  text = false,
  type = 'button',
  to = '',
  form,
  margin,
}: ButtonProps) {
  const as = useMemo(() => {
    if (href) {
      return 'a';
    }
    if (to) {
      return Link;
    }
    return 'button';
  }, [to, href]);
  return (
    <StyledButton
      $filter={filter}
      as={as}
      disabled={disabled}
      href={disabled ? '#' : href}
      icon={icon}
      onClick={onClick}
      $outline={outline}
      size={size}
      $text={text}
      type={type}
      variant={variant}
      to={to}
      form={form}
      margin={margin}>
      {children}
    </StyledButton>
  );
}
