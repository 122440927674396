import React from 'react';
import { useTranslation } from 'react-i18next';
import { addMonths, isSameMonth, isAfter, isBefore } from 'date-fns';

import { capitalize } from '@lodash';

import { Month, MonthsContainer } from './styles';

interface MonthsProps {
  displayedDate: Date;
  startDate: Date | null;
  endDate: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  onClickDate: (date: Date) => void;
}

const Months = ({
  displayedDate,
  startDate,
  endDate,
  minDate,
  maxDate,
  onClickDate,
}: MonthsProps) => {
  const { t } = useTranslation();
  const today = new Date();

  return (
    <MonthsContainer>
      {[...Array(12).keys()].map(index => {
        const month = new Date(displayedDate.getFullYear(), index);

        const isCurrent = isSameMonth(month, today);
        const isSelected =
          (startDate && isSameMonth(month, startDate)) ||
          (endDate && isSameMonth(month, endDate)) ||
          false;
        const isInRange =
          (startDate && endDate && isAfter(month, startDate) && isBefore(month, endDate)) || false;
        const isDisabled =
          (minDate && isBefore(month, minDate)) || (maxDate && isAfter(month, maxDate)) || false;

        return (
          <Month
            key={index}
            $isCurrent={isCurrent}
            $isSelected={isSelected}
            $isInRange={isInRange}
            $isDisabled={isDisabled}
            onClick={() =>
              !isDisabled &&
              onClickDate(new Date(addMonths(displayedDate, index - displayedDate.getMonth())))
            }>
            {capitalize(t('{{date, MMM}}', { date: month }))}
          </Month>
        );
      })}
    </MonthsContainer>
  );
};

export default Months;
