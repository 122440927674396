import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInstantSearch } from 'react-instantsearch';

import { SideBar } from '@new-components';
import { ToggleRefinement } from '@new-components/InstantSearch';

const Refund = () => {
  const { t } = useTranslation();
  const { indexRenderState } = useInstantSearch();
  const canRefine = indexRenderState.toggleRefinement?.type?.canRefine || false;

  const label = t('expenses.list.filters.refund');

  return (
    <SideBar.Item canOpen={canRefine}>
      <SideBar.Item.Title rightEl={<ToggleRefinement attribute="type" label={label} on="refund" />}>
        {label}
      </SideBar.Item.Title>
    </SideBar.Item>
  );
};

export default Refund;
