import React, { MutableRefObject, useCallback, useRef } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  ConfirmBackdrop,
  ConfirmButtonsContainer,
  ConfirmContainer,
  WarningContainer,
} from './styled-components';

import Button from '../Button';
import Text from '../Text';
import TextInput from '../TextInput';
import useOnClickOutside from '../../hooks/useOnClickOutside';

export default function Confirm({ data, onConfirm, onCancel }: ConfirmProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const modalRef = useRef() as MutableRefObject<HTMLDivElement>;

  useOnClickOutside(modalRef, onCancel);

  const renderArchivingChildren = useCallback(
    (props: ConfirmArchivingData) => (
      <>
        <Text>{props.question}</Text>
        <Text bold>{props.archivedData}</Text>
        {typeof props.warningText === 'string' ? (
          <Text color={theme.components.confirm.error.background}>{props.warningText}</Text>
        ) : (
          <WarningContainer>
            {(props.warningText || []).map((warningText, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Text key={i} color={theme.components.confirm.error.background}>
                {warningText}
              </Text>
            ))}
          </WarningContainer>
        )}
        <ConfirmButtonsContainer>
          <Button outline onClick={onCancel}>
            {t('globals.cancel')}
          </Button>
          <Button onClick={onConfirm}>{t('globals.archive')}</Button>
        </ConfirmButtonsContainer>
      </>
    ),
    [onCancel, onConfirm, t, theme],
  );

  const renderDestructionChildren = useCallback(
    (props: ConfirmDestructionData) => (
      <>
        {props.question && <Text>{props.question}</Text>}
        <Text bold>{props.deletedData}</Text>
        {typeof props.warningText === 'string' ? (
          <Text color={theme.components.confirm.error.background}>{props.warningText}</Text>
        ) : (
          <WarningContainer>
            {(props.warningText || []).map((warningText, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Text key={i} color={theme.components.confirm.error.background}>
                {warningText}
              </Text>
            ))}
          </WarningContainer>
        )}
        <ConfirmButtonsContainer>
          <Button outline onClick={onCancel}>
            {t('globals.cancel')}
          </Button>
          <Button variant="error" onClick={onConfirm}>
            {props.confirmText}
          </Button>
        </ConfirmButtonsContainer>
      </>
    ),
    [onCancel, onConfirm, t, theme],
  );

  const renderDuplicationChildren = useCallback(
    ({ question, onChangeText, duplicateDefaultName }: ConfirmDuplicationData) => {
      return (
        <>
          <Text>{question}</Text>
          <TextInput
            onChangeText={onChangeText}
            defaultValue={duplicateDefaultName}
            placeholder={t('expense_export_templates.attributes.name') as string}
            label={t('expense_export_templates.attributes.name')}
          />
          <ConfirmButtonsContainer>
            <Button outline onClick={onCancel}>
              {t('globals.cancel')}
            </Button>
            <Button onClick={onConfirm}>{t('globals.duplicate')}</Button>
          </ConfirmButtonsContainer>
        </>
      );
    },
    [onCancel, onConfirm, t],
  );

  const renderDefaultChildren = useCallback(
    (confirmMessage: string) => (
      <>
        <Text>{confirmMessage}</Text>
        <ConfirmButtonsContainer>
          <Button outline onClick={onCancel}>
            {t('globals.no')}
          </Button>
          <Button variant="error" onClick={onConfirm}>
            {t('globals.yes')}
          </Button>
        </ConfirmButtonsContainer>
      </>
    ),
    [onCancel, onConfirm, t],
  );

  const renderChildren = useCallback(() => {
    if (typeof data === 'string') {
      return renderDefaultChildren(data);
    }

    switch (data.type) {
      case 'archiving':
        return renderArchivingChildren(data);
      case 'destruction':
        return renderDestructionChildren(data);
      case 'duplication':
        return renderDuplicationChildren(data);
      default:
        throw new Error(`Unknown confirm type: ${data}`);
    }
  }, [
    data,
    renderDefaultChildren,
    renderArchivingChildren,
    renderDestructionChildren,
    renderDuplicationChildren,
  ]);

  return (
    <ConfirmBackdrop>
      <ConfirmContainer ref={modalRef}>{renderChildren()}</ConfirmContainer>
    </ConfirmBackdrop>
  );
}
