import React, { ReactNode, useEffect, useRef, useState } from 'react';
import tw, { styled } from 'twin.macro';
import Button, { Props as ButtonProps } from '../Button';
import SubMenu from '../SubMenu';

export type { ButtonProps };
export type Alignement = 'left' | 'right';

export type Props = React.ComponentPropsWithoutRef<'div'> & {
  button: ReactNode;
  alignement?: Alignement;
};

const StyledDropdownContainer = styled.div`
  ${tw`
    relative
  `}
`;

const StyledSubMenuContainer = styled.div<{ $alignement: Alignement }>`
  ${tw`
    absolute
    w-max
    z-50
  `}
  ${({ $alignement }) => $alignement === 'right' && tw`right-0`}
`;

const Dropdown = ({ button, children, alignement = 'left', ...props }: Props) => {
  const buttonRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleClick = (e: MouseEvent) =>
      isOpen && !buttonRef.current?.contains(e.target as HTMLInputElement) && setIsOpen(false);

    document.addEventListener('mouseup', handleClick);

    return () => {
      document.removeEventListener('mouseup', handleClick);
    };
  }, [buttonRef, isOpen]);

  const onClick = (e: React.MouseEvent) => {
    if (!isOpen) {
      setIsOpen(true);
    }
    if (isOpen && buttonRef.current?.contains(e.target as HTMLInputElement)) {
      setIsOpen(false);
    }
    e.stopPropagation();
  };

  if (!React.isValidElement(button)) {
    throw new Error('First child of Dropdown must be a Button');
  }

  return (
    <StyledDropdownContainer {...props}>
      <div ref={buttonRef}>
        <button.type {...button.props} onClick={onClick} />
      </div>
      <StyledSubMenuContainer hidden={!isOpen} $alignement={alignement}>
        {children}
      </StyledSubMenuContainer>
    </StyledDropdownContainer>
  );
};

Dropdown.Button = Button;
Dropdown.SubMenu = SubMenu;

export default Dropdown;
