import React from 'react';
import 'twin.macro';

import type { Props as CardProps } from '../Card';
import type { Props as RadioButtonProps } from '../RadioButton';

import Card from '../Card';
import RadioButton from '../RadioButton';

export type RadioButtonCardProps = CardProps & RadioButtonProps;

const RadioButtonCard = ({
  children,
  selected,
  name,
  value,
  onChange,
  icon,
  ...props
}: RadioButtonCardProps) => (
  <Card as="label" selected={selected} {...props}>
    <RadioButton name={name} value={value} onChange={onChange} checked={selected} icon={icon} />
    <span tw="ml-2 align-bottom">{children}</span>
  </Card>
);

export default RadioButtonCard;
