import React, { useRef, useState, useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'react-i18next';

import PrevNextButtonGroup, { Shade } from './PrevNextButtonGroup';

export type Props = {
  totalPage: number;
  currentPage?: number;
  onPageChange: (pageNumber: number) => void;
  shade?: Shade;
};

const StyledContainer = styled.div`
  ${tw`flex items-center gap-6`}
`;

const StyledInput = styled.input`
  ${tw`p-2 mr-2`}
  ${tw`bg-white text-xs text-center`}
  ${tw`border border-solid border-blueGray-100 rounded-lg`}
`;

const StyledText = styled.p`
  ${tw`text-xs text-blueGray-700 whitespace-nowrap`};
`;

const Pagination = ({ totalPage, onPageChange, currentPage = 1, shade = 'default' }: Props) => {
  const { t } = useTranslation();
  const InputRef = useRef<any>();
  const [previousPage, setPreviousPage] = useState<number>(currentPage);
  const [displayedValue, setDisplayedValue] = useState<number | ''>(currentPage);

  const buttonPageDisabled = (page: number) => {
    if (totalPage <= 1) {
      return 'both';
    }
    if (page === totalPage) {
      return 'next';
    }
    return page === 1 ? 'prev' : undefined;
  };

  const changePage = (newPageNum: number) => {
    if (newPageNum > 0 && newPageNum <= totalPage) {
      onPageChange(newPageNum);
    } else {
      setDisplayedValue(previousPage);
    }
  };

  useEffect(() => setDisplayedValue(currentPage), [currentPage]);

  return (
    <StyledContainer>
      <div tw="flex items-center">
        <StyledInput
          size={2}
          ref={InputRef}
          value={displayedValue}
          onFocus={e => setPreviousPage(Number(e.target.value))}
          onChange={e => {
            const { value } = e.target;

            setDisplayedValue(Number(value) ? Number(value) : '');
          }}
          onKeyDown={e => e.key === 'Enter' && InputRef.current.blur()}
          onBlur={e => changePage(Number(e.target.value))}
          aria-label="Page"
        />
        <StyledText>{`${t('pagination.of')} ${totalPage}`}</StyledText>
      </div>
      <PrevNextButtonGroup
        shade={shade}
        disabled={buttonPageDisabled(currentPage)}
        onClickNext={() => {
          setDisplayedValue(Number(InputRef.current.value) + 1);
          changePage(Number(InputRef.current.value) + 1);
        }}
        onClickPrev={() => {
          setDisplayedValue(Number(InputRef.current.value) - 1);
          changePage(Number(InputRef.current.value) - 1);
        }}
      />
    </StyledContainer>
  );
};

Pagination.PrevNextButtonGroup = PrevNextButtonGroup;

export { PrevNextButtonGroup };
export default Pagination;
