import React from 'react';
import 'twin.macro';

import Chip from '../Chip';
import ResponsiveWrapper from '../ResponsiveWrapper';

type Item = {
  label: string;
  values: string[];
};

export type Props = {
  items: Item[];
};

const StaticRefinements = ({ items }: Props) => (
  <>
    {items.map(item => (
      <Chip key={item.label} label={item.label} tw="max-w-[300px]">
        <ResponsiveWrapper>
          {item.values.map(value => (
            <span key={`${item.label}-${value}`}>{value}</span>
          ))}
        </ResponsiveWrapper>
      </Chip>
    ))}
  </>
);

export default StaticRefinements;
