import React from 'react';
import { useTranslation } from 'react-i18next';
import 'twin.macro';
import { useHits } from 'react-instantsearch';

import { useAlgoliaSettings } from '@hooks';
import { useSelectedHits } from '@contexts/SelectedHits';
import { Checkbox } from '@new-components';

const THead = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'expenses.list.table' });
  const { data: settings } = useAlgoliaSettings();
  const { ids, selectMany } = useSelectedHits();
  const { hits } = useHits();

  const hasSelected = ids.length > 0;

  return (
    <thead tw="sticky top-0 z-1">
      <tr>
        <th aria-label="Select" tw="w-12 max-w-12 p-1.5">
          <Checkbox
            isMaster
            checked={hasSelected}
            onChange={() => selectMany(hasSelected ? [] : hits)}
          />
        </th>
        <th>{t('at')}</th>
        <th>{t('type')}</th>
        <th>{t('title')}</th>
        <th>{t('expense_category')}</th>
        {settings?.displayLicensePlate && <th>{t('license_plate')}</th>}
        <th>{t('receipt')}</th>
        <th>{t('step')}</th>
        <th>{t('amount')}</th>
      </tr>
    </thead>
  );
};

export default THead;
