import React, { useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { toastify } from '@helpers/toastify';
import { GlobalStyles } from '@theme';

import { ToastContainer } from 'react-toastify';
import type { Variant } from '../Toast';

const Layout = () => {
  useLayoutEffect(() => {
    const flashes = document.querySelectorAll('.toast') as NodeListOf<HTMLElement>;

    flashes.forEach((flash: HTMLElement) => {
      const classList = Array.from(flash.classList.values());
      let type = classList.find(c => c.includes('flash-'))?.split('-')[1] || 'info';

      if (!['info', 'success', 'error', 'warning'].includes(type)) type = 'info';

      toastify(type as Variant, flash.innerText, {
        autoClose: 5000,
      });

      flash.remove();
    });
  }, []);

  return (
    <>
      <GlobalStyles />
      <Outlet />
      <ToastContainer icon={false} hideProgressBar />
    </>
  );
};

export default Layout;
