import { ForwardedRef, MutableRefObject, RefObject, useEffect, useRef } from 'react';

// Allow the synchronization of multiple references
export function useCombinedRefs<T>(...refs: (RefObject<T> | ForwardedRef<T>)[]) {
  // Target ref is used as a single point of storage for the common state all refs should have
  const targetRef = useRef<T>();

  // Anytime one of the refs change, update all refs to keep them in sync
  useEffect(() => {
    refs.forEach(ref => {
      if (!ref) return;

      // If ref is a function then it's a forwardRef and need to be called like a function to assign its value
      if (typeof ref === 'function') {
        ref(targetRef.current || null);
      } else {
        // Otherwise its a normal MutableRef so we assign its current value as normal
        // eslint-disable-next-line no-param-reassign
        (ref as MutableRefObject<T | undefined>).current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef as RefObject<T>;
}
