import React from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { useTranslation } from 'react-i18next';

import { RefinementList } from '@new-components/InstantSearch';
import { SideBar, Badge } from '@new-components';

const customOrder = ['CardExpense', 'EmployeeExpense', 'KmExpense', 'ExternalExpense', 'SupplierExpense', 'ReversedExpense' ];

const transformItems = (items: any[]) =>
  items.sort((a, b) => customOrder.indexOf(a.value) - customOrder.indexOf(b.value));

const SourceType = () => {
  const { t } = useTranslation();
  const { indexUiState, indexRenderState } = useInstantSearch();
  const length = indexUiState.refinementList?.source_type?.length || 0;
  const canRefine = indexRenderState.refinementList?.source_type?.canRefine;

  return (
    <SideBar.Item canOpen={canRefine}>
      <SideBar.Item.Title>
        {t('expenses.list.filters.source_type')}
        {length > 0 && (
          <Badge color="yellow" shape="rounded">
            {length}
          </Badge>
        )}
      </SideBar.Item.Title>
      <RefinementList
        attribute="source_type"
        i18nKeyPrefix="expenses.list.filters.source_types"
        transformItems={transformItems}
      />
    </SideBar.Item>
  );
};

export default SourceType;
