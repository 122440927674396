import i18n from 'i18next';

export const getCountries = () => i18n.t('country:countries', { returnObjects: true }) as Countries;

export const getFlagEmoji = (countryCodeAlpha3: string) => {
  const alpha3To2 = countryCodeAlpha3.toUpperCase().split('');
  alpha3To2.pop();
  const codePoints = alpha3To2.map(char => 127397 + char.charCodeAt(0));

  return String.fromCodePoint(...codePoints);
};

export const getCountryByAlpha3 = (alpha3: string) => getCountries().find(c => c.value === alpha3);
