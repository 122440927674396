import React from 'react';

import type { TagColor } from '@new-components';
import { Tag } from '@new-components';
import { useTranslation } from 'react-i18next';

type Props = {
  value: ExpenseHit['payment_status'];
};

const valueToColor: Record<ExpenseHit['payment_status'], TagColor> = {
  'paid': 'green',
  'unpaid': 'dark-blue',
};

const PaymentStepTag = ({ value }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'expenses.attributes.payment_statuses',
  });

  const color = valueToColor[value];

  return <Tag color={color}>{t(value)}</Tag>;
};

export default PaymentStepTag;
