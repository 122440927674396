import React from 'react';
import { components, ControlProps, ContainerProps } from 'react-select';
import tw from 'twin.macro';

import Select, { Props as SelectProps } from '../Select';

const Control = ({ children, ...props }: ControlProps) => (
  <components.Control {...props}>
    <div tw="flex">{children as React.ReactElement}</div>
  </components.Control>
);

const SelectContainer = ({ children, ...props }: ContainerProps<any>) => (
  <div>
    <components.SelectContainer {...props}>{children}</components.SelectContainer>
  </div>
);

const InputSelect = ({ ...props }: SelectProps) => (
  <Select
    {...props}
    styles={{
      valueContainer: baseStyles => ({ ...baseStyles, ...tw`w-5` }),
    }}
    components={{
      Control,
      SelectContainer,
      ValueContainer: components.ValueContainer,
    }}
  />
);

export default InputSelect;
