import React from 'react';
import { usePagination, PaginationProps } from 'react-instantsearch';

import StyledPagination from '../Pagination';

const Pagination = (props: PaginationProps) => {
  const { currentRefinement, nbPages, refine, canRefine } = usePagination(props);

  if (canRefine && nbPages > 0 && currentRefinement > nbPages) refine(nbPages - 1);

  return (
    <StyledPagination
      totalPage={nbPages}
      currentPage={currentRefinement + 1}
      onPageChange={pageNumber => refine(pageNumber - 1)}
    />
  );
};

export default Pagination;
