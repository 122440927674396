import styled from 'styled-components';

export const Button = styled.button<StyledButtonProps>`
  align-items: center;
  background-color: ${props => {
    if (props.$filter) {
      return props.theme.components.button.filter.default.background;
    }
    const variant = props.theme.components.button[props.variant || 'primary'];
    if (props.$outline || props.$text) {
      return 'transparent';
    }
    if (props.disabled) {
      return variant.disabled.background;
    }
    return variant.default.background;
  }};
  border-color: ${props => {
    if (props.$filter) {
      return props.theme.components.button.filter.default.border;
    }
    const variant = props.theme.components.button[props.variant || 'primary'];
    if (props.$text) {
      return 'transparent';
    }
    if (props.disabled) {
      return variant.disabled.background;
    }
    return variant.default.background;
  }};
  border-radius: ${props => (props.$filter ? '.25rem' : '2rem')};
  border-style: solid;
  border-width: ${props => (props.$text ? 0 : '1px')};
  color: ${props => {
    if (props.$filter) {
      return props.theme.components.button.filter.default.color;
    }
    const variant = props.theme.components.button[props.variant || 'primary'];
    if (props.$outline || props.$text) {
      if (props.disabled) {
        return variant.disabled.background;
      }
      return variant.default.background;
    }
    return variant.default.color;
  }};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  gap: 0.5rem;
  justify-content: center;
  margin: ${props => (props.margin ? props.margin : 'inherit')};
  padding: ${props => {
    if (props.$text) {
      return '0';
    }

    switch (props.size) {
      case 's':
        return '.25rem .5rem';
      case 'l':
        return '1rem 1.5rem';
      default:
        return '.5rem 1rem';
    }
  }};
  pointer-events: ${props => (props.disabled ? 'none' : 'pointer')};
  text-decoration: none;
  transition: all 0.3s ease;
  white-space: nowrap;

  &:active:hover:not([disabled]) {
    background-color: ${props => {
      if (props.$filter) {
        return props.theme.components.button.filter.hover.background;
      }
      const variant = props.theme.components.button[props.variant || 'primary'];
      if (props.$outline || props.$text) {
        return 'transparent';
      }
      if (props.disabled) {
        return variant.disabled.background;
      }
      return variant.hover.background;
    }};
    border-color: ${props => {
      if (props.$filter) {
        return props.theme.components.button.filter.hover.border;
      }
      const variant = props.theme.components.button[props.variant || 'primary'];
      if (props.$text) {
        return 'transparent';
      }
      if (props.disabled) {
        return variant.disabled.border;
      }
      return variant.hover.border;
    }};
    color: ${props => {
      if (props.$filter) {
        return props.theme.components.button.filter.hover.color;
      }
      const variant = props.theme.components.button[props.variant || 'primary'];
      if (props.$outline) {
        return variant.hover.border;
      }
      if (props.$text) {
        if (props.disabled) {
          return variant.disabled.background;
        }
        return variant.default.background;
      }
      return variant.default.color;
    }};
  }
`;
