import React from 'react';
import tw, { styled } from 'twin.macro';

export type Position = 'top' | 'bottom' | 'left' | 'right';
export type Arrow = 'start' | 'center' | 'end';

export type Props = React.ComponentProps<'div'> & {
  position?: Position;
  arrow?: Arrow;
  open?: boolean;
};
// TODO: add a delay for the disappearance of the tooltip
type StyledProps = {
  $position: Position;
  $arrow: Arrow;
  $open?: boolean;
};

const positions = {
  top: {
    base: tw`bottom-full left-1/2 -translate-x-1/2`,
    start: tw`-left-2.5 translate-x-0`,
    center: tw``,
    end: tw`left-auto -right-2.5 translate-x-0`,
  },
  bottom: {
    base: tw`top-full left-1/2 -translate-x-1/2`,
    start: tw`-left-2.5 translate-x-0`,
    center: tw``,
    end: tw`left-auto -right-2.5 translate-x-0`,
  },
  left: {
    base: tw`top-1/2 -translate-y-1/2 right-full`,
    start: tw`-top-2.5 translate-y-0`,
    center: tw``,
    end: tw`top-auto -bottom-2.5 translate-y-0`,
  },
  right: {
    base: tw`top-1/2 -translate-y-1/2 left-full`,
    start: tw`-top-2.5 translate-y-0`,
    center: tw``,
    end: tw`top-auto -bottom-2.5 translate-y-0`,
  },
};

const arrows = {
  top: {
    base: tw`after:(top-full border-transparent border-t-blue-700)`,
    start: tw`after:(left-4 translate-x-0)`,
    center: tw`after:(left-1/2 -translate-x-1/2)`,
    end: tw`after:(right-4)`,
  },
  bottom: {
    base: tw`
      after:(bottom-full border-transparent border-b-blue-700)`,
    start: tw`after:(left-4 translate-x-0)`,
    center: tw`after:(left-1/2 -translate-x-1/2)`,
    end: tw`after:(right-4)`,
  },
  left: {
    base: tw`after:(left-full border-transparent border-l-blue-700)`,
    start: tw`after:(top-2 translate-y-0)`,
    center: tw`after:(top-1/2 -translate-y-1/2)`,
    end: tw`after:bottom-2`,
  },
  right: {
    base: tw`after:(right-full border-transparent border-r-blue-700)`,
    start: tw`after:(top-2 translate-y-0)`,
    center: tw`after:(top-1/2 -translate-y-1/2)`,
    end: tw`after:bottom-2`,
  },
};

const paddings = {
  top: tw`pb-2.5`,
  bottom: tw`pt-2.5`,
  left: tw`pr-2.5`,
  right: tw`pl-2.5`,
};

const StyledTooltip = styled.div<StyledProps>`
  ${tw`relative p-2.5`}
  ${tw`bg-blue-700 text-white text-xs font-normal`}
  ${tw`rounded-lg`}
  ${tw`after:(absolute w-0 h-0 border-8 border-transparent)`}
  ${({ $position, $arrow }) => [arrows[$position].base, arrows[$position][$arrow]]}
  ${tw`max-w-xl`}
  ${tw`whitespace-normal`}
`;

export const Wrapper = styled.div`
  ${tw`inline relative`}
`;

const TooltipWrapper = styled.div<StyledProps>`
  ${tw`absolute z-1 overflow-hidden`}
  ${tw`w-0 h-0 p-0 opacity-0 bg-transparent`}
  ${({ $position, $arrow }) => [positions[$position].base, positions[$position][$arrow]]}

  ${Wrapper}:hover &,
  ${Wrapper} ${({ $open }) => ($open ? '&,' : '')} {
    ${tw`w-max h-auto opacity-100`}
    ${({ $position }) => paddings[$position]}
  }
`;

const Tooltip = ({
  children,
  position = 'top',
  arrow = 'start',
  open = false,
  ...props
}: Props) => (
  <TooltipWrapper $position={position} $arrow={arrow} $open={open} {...props}>
    <StyledTooltip $position={position} $arrow={arrow}>
      {children}
    </StyledTooltip>
  </TooltipWrapper>
);

Tooltip.Wrapper = Wrapper;

export default Tooltip;
