import { CountryCode } from 'libphonenumber-js';

export { DEFAULT_CURRENCY } from './currency';
export const DEFAULT_PHONE_COUNTRY = 'FR' as CountryCode;
export const PER_PAGE_VALUES = [25, 50, 100] as number[];

export { default as apiClient } from './api-client';

export const EXPENSES_PATH_TABS = [
  'all',
  'card',
  'employee',
  'km',
  'external',
  'supplier',
  'mine',
] as const;
