import React from 'react';
import tw from 'twin.macro';
import { useTranslation } from 'react-i18next';

import { useSelectedHits } from '@contexts/SelectedHits';
import { useAlgoliaSettings } from '@hooks';
import { Tr, Td, Checkbox } from '@new-components';

import TdAmount from '../TdAmount';
import Data from '../TdData';
import ReceiptTag from '../ReceiptTag';
import TypeTag from '../TypeTag';
import StepTag from '../StepTag';

export type Props = {
  hit: ExpenseHit;
};

const Hit = ({ hit }: Props) => {
  const { t } = useTranslation();
  const { data: settings } = useAlgoliaSettings();
  const { isSelected, toggle, all } = useSelectedHits();
  const isHitSelected = isSelected(hit) || all;

  const isIncoming = !hit.settled && hit.type === 'debit';

  return (
    <Tr isSelected={isHitSelected} css={isIncoming && tw`text-blueGray-800`}>
      <Td tw="w-12 max-w-12 p-1.5" aria-label="Select">
        <Checkbox
          as="span"
          checked={isHitSelected}
          readOnly
          onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
            e.stopPropagation();
            toggle(hit);
          }}
        />
      </Td>
      <Td tw="text-blueGray-800 w-28 max-w-28" href={`${hit.edit_link}?restore_page=1`}>
        {t('{{date, human}}', { date: new Date(hit.date * 1000) })}
      </Td>
      <Td
        aria-label={hit.type}
        tw="w-36 max-w-36 text-clip"
        href={`${hit.edit_link}?restore_page=1`}>
        <TypeTag value={hit.type} />
      </Td>
      <Td tw="w-52 max-w-52 truncate" href={`${hit.edit_link}?restore_page=1`}>
        <Data fallback={hit.merchant_name}>{hit.title}</Data>
      </Td>
      <Td
        aria-label={hit.expense_category}
        tw="w-36 max-w-36 truncate"
        href={`${hit.edit_link}?restore_page=1`}>
        <Data fallback={t('globals.undefined')}>{hit.expense_category}</Data>
      </Td>
      {settings?.displayLicensePlate && (
        <Td
          aria-label={hit.license_plate}
          tw="w-24 max-w-24"
          href={`${hit.edit_link}?restore_page=1`}>
          <Data>{hit.license_plate}</Data>
        </Td>
      )}
      <Td aria-label={hit.receipt} tw="w-24 max-w-24" href={`${hit.edit_link}?restore_page=1`}>
        <ReceiptTag value={hit.receipt} />
      </Td>
      <Td aria-label={hit.step} tw="w-24 max-w-24" href={`${hit.edit_link}?restore_page=1`}>
        <StepTag value={hit.step} />
      </Td>
      <TdAmount hit={hit} isIncoming={isIncoming} />
    </Tr>
  );
};

export default Hit;
