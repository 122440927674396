import axios, { AxiosResponseHeaders } from 'axios';
import LinkHeader from 'http-link-header';

const apiClient = axios.create({
  baseURL: '/',
});

const formatPagination = (
  headers:
    | AxiosResponseHeaders
    | Partial<Record<string, string> & { 'set-cookie'?: string[] | undefined }>,
) => {
  if (headers?.link) {
    const parsedLink = LinkHeader.parse(headers.link);
    return {
      pagination: parsedLink.refs.reduce(
        (acc, link) => ({ ...acc, [link.rel]: link.uri ? parseInt(link.uri, 10) : null }),
        {},
      ),
      total: parseInt(headers['x-total-count'] || '0', 10),
    };
  }
  return undefined;
};

export const getCSRFToken = () => {
  const element = document.querySelector('meta[name=csrf-token]') as HTMLMetaElement;
  if (element) {
    return element.content;
  }
  return undefined;
};

apiClient.interceptors.response.use(
  response =>
    ({
      data: response.data,
      ...formatPagination(response.headers as AxiosResponseHeaders),
    }) as any,
  error => Promise.reject(error),
);

apiClient.interceptors.request.use(
  request => ({
    ...request,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Unicorn-Request': 'true',
      'X-Requested-With': 'XMLHttpRequest',
      ...request.headers,
      'X-CSRF-Token': getCSRFToken(),
    } as any,
  }),
  Promise.reject,
);

export default apiClient;
