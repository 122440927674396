import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useCurrentRefinements,
  useClearRefinements,
  CurrentRefinementsProps,
} from 'react-instantsearch';
import 'twin.macro';

import { Button, Chip, ResponsiveWrapper } from '@new-components';
import { getCountryByAlpha3 } from '@helpers/countries';

type CurrentRefinementsRefinement = {
  attribute: string;
  label: string;
  value: string | number;
  operator?: string;
  type: 'facet' | 'exclude' | 'disjunctive' | 'hierarchical' | 'numeric' | 'query' | 'tag';
};

type CurrentRefinementsItem = {
  attribute: string;
  refinements: CurrentRefinementsRefinement[];
};

type AmountRefinementsProps = {
  refinements: CurrentRefinementsRefinement[];
  currency: string;
};

type Props = CurrentRefinementsProps;

const keyPrefixes: Record<string, string> = {
  step: 'expenses.attributes.step',
  receipt: 'expenses.attributes.receipt_status',
  accountability: 'expenses.attributes.accountability',
  billable: 'expenses.list.filters.billables',
  reimbursement_status: 'expenses.list.filters.reimbursement_statuses',
  source_type: 'expenses.list.filters.source_types',
  complete: 'expenses.list.filters.boolean',
  settled: 'expenses.list.filters.boolean',
  payment_status: 'expenses.attributes.payment_statuses',
};

const cleanLabel = (label: string) => label.match(/^[0-9]+_(.*)$/)?.[1] || label;

const DateRefinements = ({ refinements }: { refinements: CurrentRefinementsRefinement[] }) => {
  const { t } = useTranslation();
  const [start, end] = refinements.sort(({ operator: a }) => (a === '>=' ? -1 : 1));

  return (
    <span>
      {start?.operator === '>=' && t('{{date, human}}', { date: Number(start.value) * 1000 })}
      {start?.operator === '<=' && t('globals.until_date', { date: Number(start.value) * 1000 })}
      {/* TODO: Remove "max" when useRange hook is fixed: https://github.com/algolia/instantsearch/issues/5511 */}
      {(end?.value as number) < Number.MAX_SAFE_INTEGER &&
        t(' → {{date, human}}', { date: Number(end.value) * 1000 })}
    </span>
  );
};

const AmountRefinements = ({ refinements, currency }: AmountRefinementsProps) => {
  const { t } = useTranslation();

  const min = refinements.find(({ operator }) => operator === '>=');
  const max = refinements.find(({ operator }) => operator === '<=');

  if (min && max && Number(max.value) < Number.MAX_SAFE_INTEGER)
    return (
      <span>
        {t('globals.from_min_to_max', {
          min: Number(min.value) / 100,
          max: Number(max.value) / 100,
          currency,
        })}
      </span>
    );
  if (min) return <span>{t('globals.from_min', { min: Number(min.value) / 100, currency })}</span>;
  if (max && Number(max.value) < Number.MAX_SAFE_INTEGER)
    return <span>{t('globals.to_max', { max: Number(max.value) / 100, currency })}</span>;
};

const RefundRefinements = ({ refinements }: { refinements: CurrentRefinementsRefinement[] }) => {
  const { t } = useTranslation();

  const hasRefundRefinement = refinements.some(r => r.value === 'refund');

  return hasRefundRefinement ? <span>{t('globals.yes')}</span> : null;
};

const LocationRefinements = ({ refinements }: { refinements: CurrentRefinementsRefinement[] }) => (
  <ResponsiveWrapper>
    {refinements.map(r => {
      const label = getCountryByAlpha3(r.label)?.label || r.label;
      return <span key={r.label + r.value}>{label}</span>;
    })}
  </ResponsiveWrapper>
);

const ItemRefinements = ({ attribute, refinements }: CurrentRefinementsItem) => {
  const { t } = useTranslation();
  const { refine } = useClearRefinements({
    includedAttributes: [attribute],
  });

  const keyPrefix = keyPrefixes[attribute];
  const filter = attribute === 'type' ? 'refund' : attribute;

  return (
    <Chip label={t(`expenses.list.filters.${filter}`)} onClickRemove={refine} tw="max-w-[300px]">
      {attribute === 'date' && <DateRefinements refinements={refinements} />}
      {attribute === 'due_at' && <DateRefinements refinements={refinements} />}
      {attribute === 'amount' && <AmountRefinements refinements={refinements} currency="€" />}
      {attribute === 'type' && <RefundRefinements refinements={refinements} />}
      {attribute === 'location' && <LocationRefinements refinements={refinements} />}
      {attribute === 'reimbursement_id' && null}
      {!['date', 'due_at', 'amount', 'type', 'reimbursement_id', 'location'].includes(attribute) && (
        <ResponsiveWrapper>
          {refinements.map(r => (
            <span key={r.label + r.value}>
              {r.label !== 'undefined'
                ? t(cleanLabel(r.label as string), { keyPrefix })
                : t('globals.undefined')}
            </span>
          ))}
        </ResponsiveWrapper>
      )}
    </Chip>
  );
};

const CurrentRefinements = (props: Props) => {
  const { t } = useTranslation();
  const { items } = useCurrentRefinements(props);
  const { refine } = useClearRefinements();

  /* TODO: Remove "max" when useRange hook is fixed: https://github.com/algolia/instantsearch/issues/5511 */
  const filteredItems = items.filter(i => {
    if (i.refinements[0] && i.refinements[0].value === Number.MAX_SAFE_INTEGER) return false;
    // if (indexId && i.indexId !== indexId) return false;
    return true;
  });

  if (filteredItems.length === 0) return null;

  return (
    <>
      {filteredItems.map(item => (
        <ItemRefinements
          key={item.attribute}
          attribute={item.attribute}
          refinements={item.refinements}
        />
      ))}
      {filteredItems.length > 0 && (
        <Button onClick={refine} size="xs" shade="link">
          {t('globals.reset')}
        </Button>
      )}
    </>
  );
};

export default CurrentRefinements;
