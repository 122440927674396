import React, { useEffect, useState } from 'react';
import { useSearchBox, SearchBoxProps } from 'react-instantsearch';
import { useDebouncedCallback } from 'use-debounce';

import SearchInput from '../SearchInput';

export type Props = SearchBoxProps & {
  wait?: number;
};

const SearchBox = ({ placeholder, wait, ...props }: Props) => {
  const { query, refine, clear } = useSearchBox(props);
  const [value, setValue] = useState(query);

  const debouncedRefine = useDebouncedCallback(newValue => {
    if (newValue) refine(newValue);
    else clear();
  }, wait);

  const onChangeValue = (newValue: string) => {
    setValue(newValue);
    debouncedRefine(newValue);
  };

  useEffect(() => {
    setValue(query);
  }, [query]);

  return (
    <SearchInput
      placeholder={placeholder}
      value={value}
      onChangeValue={onChangeValue}
      onClickClear={() => onChangeValue('')}
    />
  );
};

export default SearchBox;
