import styled from 'styled-components';

import Text from '../Text';

export const IconContainer = styled.div<{ hasError?: boolean }>`
  align-items: center;
  background-color: ${props =>
    props.hasError
      ? props.theme.components.inputText.withIcon.error.background
      : props.theme.components.inputText.withIcon.default.background};
  border: 1px solid
    ${props =>
      props.hasError
        ? props.theme.components.inputText.withIcon.error.border
        : props.theme.components.inputText.withIcon.default.border};
  border-radius: 0.25rem;
  display: flex;
  input {
    border: none;
    border-radius: 0;
  }
  justify-content: start;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: ${props =>
      props.hasError
        ? props.theme.components.inputText.withIcon.error.background
        : props.theme.components.inputText.withIcon.hover.background};
  }
  &:focus-within {
    background-color: ${props => props.theme.components.inputText.withIcon.focused.background};
    border-color: ${props => props.theme.components.inputText.withIcon.focused.border};
  }
  .svg-inline--fa {
    margin: 1rem;
  }
  width: 100%;
`;

export const TextInput = styled.input<{ hasError?: boolean }>`
  align-self: stretch;
  border: 1px solid
    ${props =>
      props.hasError
        ? props.theme.components.inputText.error.border
        : props.theme.components.inputText.default.border};
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: ${props => props.theme.components.inputText.default.color};
  font-size: 1rem;
  margin: 0;
  outline: none;
  padding: 1rem;
  transition: all 0.3s ease-in-out;
  width: 100%;

  &::placeholder {
    color: ${props => props.theme.components.inputText.default.border};
    font-size: 1rem;
  }

  &:hover {
    border-color: ${props => props.theme.components.inputText.hover.border};
  }

  &:focus {
    border-color: ${props => props.theme.components.inputText.focused.border};
  }
`;

export const TextInputContainer = styled.div<{ hasError?: boolean }>`
  align-items: flex-start;
  align-self: stretch;
  display: inline;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

export const ErrorContainer = styled.div`
  align-items: flex-start;
  display: flex;
  height: 1rem;
  width: 100%;
`;

export const Error = styled(Text)`
  color: ${props => props.theme.components.inputText.error.color};
`;

export const TextInputLabelTooltipContainer = styled.div`
  display: flex;
  margin-top: 1.5rem;

  span {
    margin-bottom: 0.5rem;
  }
`;

export const LabelToolTipTextContainer = styled.div`
  bottom: 0.9rem;
  position: relative;

  .multiline-tooltip {
    max-width: 300px;
    white-space: pre-wrap;
  }
`;

export const RequiredAsterisk = styled(Text)`
  bottom: 0.25rem;
  margin-right: 0.25rem;
  position: relative;
`;
