import { createGlobalStyle } from 'styled-components';

// TODO: to be removed when the new theme is ready
export default createGlobalStyle`
  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: ${props => props.theme.components.globalStyle.primary.background};
    color: ${props => props.theme.components.globalStyle.primary.color};
    font-family: "Roboto", "Helvetica", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;

    a {
      color: ${props => props.theme.components.link.default.color};
      display: inline;
      transition: color .3s ease;

      &:hover:not([disabled]) {
        color: ${props => props.theme.components.link.hover.color};
      }
    }
  }
`;
