import React from 'react';
import 'twin.macro';

import Badge from '../Badge';
import Tag from '../Tag';

export type Props = {
  value: string | number;
  children: string;
};

const TagWithNumber = ({ value, children }: Props) => (
  <Tag color="medium-blue" tw="py-2 pl-3 pr-3.5 rounded-lg">
    {!!value && (
      <Badge color="yellow" shape="oval">
        {value}
      </Badge>
    )}
    {!!children && <span tw="ml-2">{children}</span>}
  </Tag>
);

export default TagWithNumber;
