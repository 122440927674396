import React from 'react';
import 'twin.macro';

export type Props = React.ComponentPropsWithoutRef<'td'> & {
  href?: string;
};

const Td = ({ href, children, ...props }: Props) => {
  return (
    <td {...props}>
      {children}
      {href && <a href={href} aria-label="link" tw="absolute inset-0 block" />}
    </td>
  );
};

export default Td;
