import React from 'react';
import tw, { styled } from 'twin.macro';

export type Props = React.ComponentProps<'input'> & {
  icon?: React.ReactElement;
  iconChecked?: React.ReactElement;
};

const Icon = styled.span`
  ${tw`[> svg]:(w-3 h-3)`}
`;

const IconChecked = styled.span`
  ${tw`hidden [> svg]:(w-3 h-3)`}
`;

const ToggleSlider = styled.span`
  ${tw`absolute top-0.5 left-0.5 bg-white rounded-full h-4 w-4 p-0.5 transition-transform`}
`;

const StyledToggle = styled.span`
  ${tw`relative block cursor-pointer`}
  ${tw`w-9 h-5 rounded-full shadow-inner bg-gray-200 text-blueGray-200`}

  input:checked + & {
    ${tw`bg-blue-100 border-blue-100`}
  }

  input:checked + & > ${ToggleSlider} {
    ${tw`translate-x-full text-blue-100`}
  }

  input:checked + & > ${ToggleSlider} > ${Icon} {
    ${tw`hidden`}
  }

  input:checked + & > ${ToggleSlider} > ${IconChecked} {
    ${tw`block`}
  }
`;

const Toggle = ({ icon, iconChecked, ...props }: Props) => (
  <label>
    <input {...props} type="checkbox" tw="sr-only" />
    <StyledToggle>
      <ToggleSlider>
        {icon && <Icon>{icon}</Icon>}
        {iconChecked && <IconChecked>{iconChecked}</IconChecked>}
      </ToggleSlider>
    </StyledToggle>
  </label>
);

export default Toggle;
