import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { startOfDay, endOfDay, endOfMonth, isSameDay, isSameMonth } from 'date-fns';
import 'twin.macro';

import { Calendar } from 'iconoir-react';

import useForwardRef from '@hooks/useForwardRef';

import { ClearRightAction } from '../InputWrapper';
import InputText, { Props as InputTextProps } from '../InputText';
import Picker, { Modes } from './Picker';

export type DateRange = [Date | null, Date | null];

export type Props = Omit<
  InputTextProps,
  'icon' | 'rightEl' | 'isFocused' | 'value' | 'onChangeValue' | 'min' | 'max'
> & {
  value?: Date | DateRange | null;
  onChangeValue?: (value: Date | DateRange) => void;
  onClickClear?: () => void;
  autoHide?: boolean;
  isRange?: boolean;
  max?: Date | null;
  min?: Date | null;
};

const isEmpty = (value?: Date | DateRange | null) => {
  if (Array.isArray(value)) return !value[0] && !value[1];
  return !value;
};

const DatePicker = React.forwardRef(
  (
    {
      value,
      onChangeValue,
      onClickClear,
      autoHide = true,
      disabled = false,
      max,
      min,
      ...props
    }: Props,
    ref,
  ) => {
    const { t } = useTranslation();

    const inputRef = useForwardRef<HTMLInputElement>(ref as React.RefObject<HTMLInputElement>);
    const datePickerRef = useRef<HTMLDivElement>(null);

    const [show, setShow] = useState<boolean>(false);
    const [mode, setMode] = useState<Modes>('days');

    const upValue = useCallback(() => {
      if (mode === 'months' && Array.isArray(value) && value[0] && !value[1])
        onChangeValue?.([value[0], endOfMonth(value[0])]);
    }, [mode, value, onChangeValue]);

    const handleClickOutside = useCallback(
      (event: MouseEvent) => {
        if (!datePickerRef?.current) return;
        if (!datePickerRef.current.contains(event.target as Node)) {
          upValue();
          setShow(false);
        }
      },
      [upValue],
    );

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside, true);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside, true);
      };
    }, [datePickerRef, inputRef, handleClickOutside]);

    const getInputValue = () => {
      const format = mode === 'months' ? 'month_year' : 'human';

      if (Array.isArray(value) && value[0]) {
        if (value[1]) {
          if (mode === 'months' && isSameMonth(value[0], value[1]))
            return t(`{{start, ${format}}}`, { start: value[0] });
          if (mode === 'days' && isSameDay(value[0], value[1]))
            return t(`{{start, ${format}}}`, { start: value[0] });
          return t(`{{start, ${format}}} → {{end, ${format}}}`, { start: value[0], end: value[1] });
        }
        return t(`{{start, ${format}}}`, { start: value[0] });
      }
      if (value instanceof Date) return t(`{{date, ${format}}}`, { date: value });

      return '';
    };

    const onChange = (v: Date | DateRange) => {
      props.onChange?.({ target: { value: v } } as unknown as React.ChangeEvent<HTMLInputElement>);
      onChangeValue?.(v);
    };

    const rightEl =
      !disabled && onClickClear && !isEmpty(value) ? (
        <ClearRightAction
          onClick={e => {
            e.stopPropagation();
            onClickClear();
          }}
        />
      ) : undefined;

    return (
      <div className="w-full relative" ref={datePickerRef}>
        <div>
          <InputText
            {...props}
            ref={inputRef}
            tabIndex={0}
            disabled={disabled}
            icon={<Calendar />}
            readOnly
            value={getInputValue()}
            onFocus={() => setShow(true)}
            rightEl={rightEl}
            tw="truncate cursor-pointer"
          />
        </div>
        {!disabled && show && (
          <Picker
            date={value}
            onChangeDate={onChange}
            setShow={setShow}
            autoHide={autoHide}
            mode={mode}
            setMode={setMode}
            minDate={min && startOfDay(min)}
            maxDate={max && endOfDay(max)}
          />
        )}
      </div>
    );
  },
);

DatePicker.displayName = 'DatePicker';

export default DatePicker;
