import { transform, camelCase, snakeCase, isObject } from '@lodash';

export const camelize = (obj: Record<string, any> | Record<string, any>[]) =>
  transform(obj, (acc: Record<string, any>, value, key, target) => {
    const camelKey = Array.isArray(target) ? key : camelCase(key);

    acc[camelKey] = isObject(value) ? camelize(value) : value;
  });

export const snakeize = (obj: Record<string, any> | Record<string, any>[]) =>
  transform(obj, (acc: Record<string, any>, value, key, target) => {
    const snakeKey = Array.isArray(target) ? key : snakeCase(key);

    acc[snakeKey] = isObject(value) ? snakeize(value) : value;
  });
