import React from 'react';

import type { TagColor } from '@new-components';
import { Tag } from '@new-components';
import { useTranslation } from 'react-i18next';

import Undefined from './Undefined';

type Props = {
  value: ExpenseHit['receipt'];
};

const valueToColor: Record<ExpenseHit['receipt'], TagColor> = {
  attached: 'green',
  lost: 'orange',
  missing: 'red',
  not_required: 'grey',
};

const ReceiptTag = ({ value }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'expenses.attributes.receipt_status',
  });
  const color = valueToColor[value] || 'grey';

  return value !== 'not_required' ? (
    <Tag color={color}>{t(value)}</Tag>
  ) : (
    <Undefined>{t(value)}</Undefined>
  );
};

export default ReceiptTag;
