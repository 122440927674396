import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { queryClient } from '@constants/query-client';

import Screens from './screens';
import './i18n';

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools />
    <Screens />
  </QueryClientProvider>
);

export default App;
