import styled from 'styled-components';

export const ConfirmBackdrop = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
`;
export const ConfirmContainer = styled.div`
  align-items: center;
  background-color: ${props => props.theme.components.confirm.default.background};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 2rem;
`;

export const ConfirmButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const WarningContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0;
`;
