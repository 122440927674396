import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import loadable from '@loadable/component';

import { Layout as NewLayout } from '@new-components';
import { Layout as OldLayout } from '@components';

import ExpensesLayout from './expenses';
import AllExpenses from './expenses/all';
import MyExpenses from './expenses/mine';
import CardExpenses from './expenses/card';
import ExternalExpenses from './expenses/external';
import EmployeeExpenses from './expenses/employee';
import KmExpenses from './expenses/km';
import SupplierExpenses from './expenses/supplier';

const AddBusinessCodeForm = loadable(() => import('./business-codes/new'));
const BusinessCodesList = loadable(() => import('./business-codes/list'));
const EditBusinessCodeForm = loadable(() => import('./business-codes/edit'));
const AddAnalyticCodeForm = loadable(() => import('./analytic-codes/new'));
const AnalyticCodesList = loadable(() => import('./analytic-codes/list'));
const EditAnalyticCodeForm = loadable(() => import('./analytic-codes/edit'));
const AddAnalyticalAxesForm = loadable(() => import('./analytical-axes/new'));
const AnalyticalAxesList = loadable(() => import('./analytical-axes/list'));
const EditAnalyticalAxesForm = loadable(() => import('./analytical-axes/edit'));
const EditExpenseCategory = loadable(() => import('./expense-categories/edit'));
const ExpenseCategoriesList = loadable(() => import('./expense-categories/list'));
const ImportExpenseCategory = loadable(() => import('./expense-categories/import'));
const NewExpenseCategory = loadable(() => import('./expense-categories/new'));
const AccountsList = loadable(() => import('./accounts/list'));
const AddDriverCodeForm = loadable(() => import('./driver-codes/new'));
const DriverCodesList = loadable(() => import('./driver-codes/list'));
const EditDriverCodeForm = loadable(() => import('./driver-codes/edit'));
const CardOrdersList = loadable(() => import('./card-orders/list'));
const CardPatternsList = loadable(() => import('./card-patterns/list'));
const AddContactForm = loadable(() => import('./contacts/new'));
const ContactsList = loadable(() => import('./contacts/list'));
const EditContactForm = loadable(() => import('./contacts/edit'));
const Dashboard = loadable(() => import('./dashboard'));
const DepartmentsList = loadable(() => import('./departments/list'));
const EditExpenseExportTemplate = loadable(() => import('./expense-export-templates/edit'));
const ExpenseExportTemplatesList = loadable(() => import('./expense-export-templates/list'));
const NewExpenseExportTemplate = loadable(() => import('./expense-export-templates/new'));
const EmployeesList = loadable(() => import('./employees/list'));
const PaymentErrorsList = loadable(() => import('./payment-errors/list'));
const ShowExpenseExportTemplate = loadable(() => import('./expense-export-templates/show'));
const VatRatesList = loadable(() => import('./vat-rates/list'));
const Companies = loadable(() => import('./companies/list'));
const TemporaryCardDeactivationRulesList = loadable(
  () => import('./temporary-card-deactivation-rules/list'),
);
const TemporaryCardDeactivationRulesEdit = loadable(
  () => import('./temporary-card-deactivation-rules/edit'),
);
const TemporaryCardDeactivationRulesNew = loadable(
  () => import('./temporary-card-deactivation-rules/new'),
);
const CompanyBankInformation = loadable(() => import('./company-bank-information'));
const Suppliers = loadable(() => import('./suppliers/list'));

const Screens = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<NewLayout />}>
          {/* NOTE: screens using new design system go here */}
          <Route path="/expenses" element={<ExpensesLayout />}>
            <Route index element={<AllExpenses />} />
            <Route path="all" element={<AllExpenses />} />
            <Route path="card" element={<CardExpenses />} />
            <Route path="mine" element={<MyExpenses />} />
            <Route path="external" element={<ExternalExpenses />} />
            <Route path="employee" element={<EmployeeExpenses />} />
            <Route path="km" element={<KmExpenses />} />
            <Route path="supplier" element={<SupplierExpenses />} />
          </Route>
          {/* TODO: Suppliers - Add an Layout/Outlet combo for the drawer gesture */}
          <Route path="/suppliers" element={<Suppliers />} />
          <Route path="/suppliers/:id" element={<Suppliers routeState="show" />} />
          <Route path="/suppliers/new" element={<Suppliers routeState="new" />} />
          <Route path="/suppliers/:id/edit" element={<Suppliers routeState="edit" />} />
        </Route>
        {/* TODO: migrate all screens on NewLayout - https://mooncard.atlassian.net/browse/MOON-1656 */}
        <Route path="/" element={<OldLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/accounts" element={<AccountsList />} />
          <Route path="/analytical_axes" element={<AnalyticalAxesList />} />
          <Route path="/analytical_axes/new" element={<AddAnalyticalAxesForm />} />
          <Route path="/analytical_axes/:id/edit" element={<EditAnalyticalAxesForm />} />
          <Route path="/analytic_codes" element={<AnalyticCodesList />} />
          <Route path="/analytic_codes/new" element={<AddAnalyticCodeForm />} />
          <Route path="/analytic_codes/:id/edit" element={<EditAnalyticCodeForm />} />
          <Route path="/business_codes" element={<BusinessCodesList />} />
          <Route path="/business_codes/new" element={<AddBusinessCodeForm />} />
          <Route path="/business_codes/:id/edit" element={<EditBusinessCodeForm />} />
          <Route path="/card_orders" element={<CardOrdersList />} />
          <Route path="/card_patterns" element={<CardPatternsList />} />
          <Route path="/contacts" element={<ContactsList />} />
          <Route path="/contacts/:id/edit" element={<EditContactForm />} />
          <Route path="/contacts/new" element={<AddContactForm />} />
          <Route path="/departments" element={<DepartmentsList />} />
          <Route path="/driver_codes" element={<DriverCodesList />} />
          <Route path="/driver_codes/new" element={<AddDriverCodeForm />} />
          <Route path="/driver_codes/:id/edit" element={<EditDriverCodeForm />} />
          <Route path="/employees" element={<EmployeesList />} />
          <Route path="/expense_categories" element={<ExpenseCategoriesList />} />
          <Route path="/expense_categories/new" element={<NewExpenseCategory />} />
          <Route path="/expense_categories/:id/edit" element={<EditExpenseCategory />} />
          <Route path="/expense_categories/new_import" element={<ImportExpenseCategory />} />
          <Route path="/vat_rates" element={<VatRatesList />} />
          <Route path="/expense_export_templates" element={<ExpenseExportTemplatesList />} />
          <Route path="/expense_export_templates/new" element={<NewExpenseExportTemplate />} />
          <Route path="/payment_errors" element={<PaymentErrorsList />} />
          <Route path="/companies" element={<Companies />} />
          <Route
            path="/expense_export_templates/:id/edit"
            element={<EditExpenseExportTemplate />}
          />
          <Route path="/expense_export_templates/:id" element={<ShowExpenseExportTemplate />} />
          <Route
            path="/temporary_card_deactivation_rules"
            element={<TemporaryCardDeactivationRulesList />}
          />
          <Route
            path="/temporary_card_deactivation_rules/new"
            element={<TemporaryCardDeactivationRulesNew />}
          />
          <Route
            path="/temporary_card_deactivation_rules/:id/edit"
            element={<TemporaryCardDeactivationRulesEdit />}
          />
          <Route path="/company_bank_information" element={<CompanyBankInformation />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Screens;
