import React from 'react';
import { useHits, HitsProps, useInstantSearch } from 'react-instantsearch';
import 'twin.macro';

import { useDebounce } from 'use-debounce';

export type Props = HitsProps<ExpenseHit> & {
  thead: React.ReactNode;
  skeleton: React.JSXElementConstructor<{}>;
  emptyMessage: React.ReactNode;
  errorMessage: React.ReactNode;
};

const HitsTable = ({
  thead,
  hitComponent,
  skeleton,
  emptyMessage,
  errorMessage,
  ...props
}: Props) => {
  const { status } = useInstantSearch();
  const { hits, sendEvent } = useHits(props);
  const [dbStatus] = useDebounce(status, 250);

  if (!hitComponent) {
    throw new Error('hitComponent is undefined');
  }

  const HitComponent = hitComponent;
  const Skeleton = skeleton;

  const isLoading = dbStatus === 'loading' || dbStatus === 'stalled';
  const isEmpty = dbStatus === 'idle' && !hits.length;
  const hasError = dbStatus === 'error';

  return (
    <>
      <table>
        {thead}
        <tbody>
          {hits.map(hit => (
            <HitComponent key={hit.objectID} hit={hit} sendEvent={sendEvent} />
          ))}
          {isLoading && [1, 2, 3].map(i => <Skeleton key={`sk-${i}`} />)}
        </tbody>
      </table>
      {isEmpty && emptyMessage}
      {hasError && errorMessage}
    </>
  );
};

export default HitsTable;
