import React from 'react';
import { StyledAnchor, StyledTooltip } from './styled-components';

const Tooltip = ({ id, anchor, place, children }: TooltipProps) => {
  const formattedId = id.replaceAll(' ', '-');

  return (
    <>
      <StyledAnchor id={formattedId}>{anchor}</StyledAnchor>
      <StyledTooltip anchorSelect={`#${formattedId}`} place={place}>
        {children}
      </StyledTooltip>
    </>
  );
};

export default Tooltip;
