import React from 'react';
import tw, { styled } from 'twin.macro';

export type Props = React.PropsWithChildren;
export type ItemProps = React.ComponentPropsWithoutRef<'input'>;

const CheckboxContainer = styled.label`
  ${tw`
    flex items-center
    overflow-hidden
    bg-white text-blueGray-700 text-xs
    border border-solid border-blueGray-100 border-r-0
    first:(rounded-l-lg)
    last:(rounded-r-lg border-l border-r)
    hover:(bg-yellow-050)
    cursor-pointer
  `}
`;

const StyledContent = styled.span`
  ${tw`
    w-full h-full
    py-2 px-3
    hover:(bg-yellow-050)
  `}

  input:checked + & {
    ${tw`bg-yellow-050 text-blue-500 font-semibold`}
  }
`;

const ListGroupItem = ({ children, ...props }: ItemProps) => {
  return (
    <CheckboxContainer>
      <input {...props} type="radio" tw="hidden" />
      <StyledContent>{children}</StyledContent>
    </CheckboxContainer>
  );
};

const ListGroup = ({ children }: Props) => <div tw="flex">{children}</div>;

ListGroup.Item = ListGroupItem;

export default ListGroup;
