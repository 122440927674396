import React from 'react';
import { useTranslation } from 'react-i18next';
import { differenceInDays } from 'date-fns';
import { Calendar, WarningTriangle } from 'iconoir-react';
import 'twin.macro';

export type Props = {
  hit: ExpenseHit;
};

const Status = ({ hit }: Props) => {
  const { t } = useTranslation();
  const today = new Date();
  const dueDate = new Date(hit.due_at * 1000);
  const dayDifference = differenceInDays(dueDate, today);

  if (hit.payment_status === 'paid' || hit.due_at === 0) {
    return null;
  }

  const dueText =
    dayDifference === 0
      ? t('expenses.list.table.due_today')
      : t('expenses.list.table.due_in', { days: dayDifference });

  if (dayDifference < 0 && hit.payment_status === 'unpaid') {
    return (
      <span tw="flex items-center gap-1 text-red-700 font-semibold">
        <WarningTriangle tw="inline-block w-4 h-4" />
        <span tw="inline-block text-xs">
          {t('expenses.list.table.delay', { days: -dayDifference })}
        </span>
      </span>
    );
  }

  return (
    <span tw="flex items-center gap-1 text-blueGray-700">
      <Calendar tw="inline-block w-4 h-4" />
      <span tw="inline-block text-xs">{dueText}</span>
    </span>
  );
};

export default Status;
