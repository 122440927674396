import React from 'react';
import { ToggleRefinementProps, useToggleRefinement } from 'react-instantsearch';
import Toggle from '../Toggle';

const ToggleRefinement = ({ label, ...props }: ToggleRefinementProps) => {
  const { value, refine, canRefine } = useToggleRefinement(props);

  const count = value.onFacetValue.count || 0;
  const disabled = !canRefine || count === 0;
  const checked = value.isRefined && count > 0;

  return (
    <Toggle
      aria-label={label}
      checked={checked}
      onChange={event => refine({ isRefined: !event.target.checked })}
      disabled={disabled}
    />
  );
};

export default ToggleRefinement;
