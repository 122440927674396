import { apiClient } from '../constants';

export const searchBusinessCodes = (params?: ApiParams) =>
  apiClient.post<PaginatedSearchRequest<BusinessCode>, PaginatedSearchRequest<BusinessCode>>(
    '/business_codes/search',
    params,
  );
export const getBusinessCodesSearch = () =>
  apiClient.get<PaginatedSearchRequest<BusinessCode>, PaginatedSearchRequest<BusinessCode>>(
    '/business_codes/search',
  );
export const deleteBusinessCodesSearch = () =>
  apiClient.delete<PaginatedSearchRequest<BusinessCode>, PaginatedSearchRequest<BusinessCode>>(
    '/business_codes/search',
  );
export const deleteBusinessCode = (id: BusinessCode['id']) =>
  apiClient.delete(`/business_codes/${id}.json`);

export const archiveBusinessCode = (id: BusinessCode['id']) =>
  apiClient.post(`/business_codes/${id}/toggle_archive.json`);

export const addBusinessCode = (params?: BusinessCodeForm) =>
  apiClient.post<BusinessCode, BusinessCode>('/business_codes', params);

export const importBusinessCodesFile = (formData: FormData) =>
  apiClient.post('/business_codes/import', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const updateBusinessCode = (params: BusinessCodeForm, id?: string) =>
  apiClient.patch<BusinessCode, BusinessCode>(`/business_codes/${id}.json`, params);

export const getBusinessCode = (id?: string) => apiClient.get(`/business_codes/${id}.json`);
