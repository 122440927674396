import tw, { styled } from 'twin.macro';

type BaseProps = {
  $isCurrent: boolean;
  $isInRange: boolean;
  $isSelected: boolean;
  $isDisabled: boolean;
};

type DayProps = BaseProps & {
  $isCurrentMonthAndYear: boolean;
};

type YearProps = BaseProps & {
  $isBeside: boolean;
};

export const WeekDaysContainer = styled.div`
  ${tw`grid grid-cols-7 mb-1`}
`;

export const WeekDay = styled.span`
  ${tw`
    h-6
    text-xs font-bold text-center
    text-blue-700
  `}
`;

export const DaysContainer = styled.div`
  ${tw`
    grid
    w-64
    grid-cols-7
    gap-1
  `}
`;

export const Base = styled.span<BaseProps>`
  ${tw`
    flex-1 block
    rounded-lg cursor-pointer
    text-sm leading-9 text-center
    text-blue-700 hover:(bg-blue-005 text-blue-100)
  `}

  ${({ $isInRange }) => $isInRange && tw`bg-yellow-050`}
  ${({ $isSelected }) =>
    $isSelected && tw`bg-primary text-white font-semibold hover:(text-white bg-primary)`}
  ${({ $isCurrent }) => $isCurrent && tw`border border-blue-050`}
  ${({ $isDisabled }) =>
    $isDisabled && tw`bg-white text-gray-500 cursor-not-allowed hover:(bg-white text-gray-500)`};
`;

export const Day = styled(Base)<DayProps>`
  ${tw`
    flex items-center justify-center
    h-8 w-8 rounded-full
  `}

  /* ${({ $isCurrentMonthAndYear, $isInRange, $isSelected }) =>
    !$isCurrentMonthAndYear && !$isInRange && !$isSelected && tw`text-gray-500`} */
  ${({ $isCurrentMonthAndYear }) => !$isCurrentMonthAndYear && tw`invisible`}
`;

export const MonthsContainer = styled.div`
  ${tw`
    grid
    w-64
    grid-cols-4
    gap-1
  `}
`;

export const Month = Base;

export const YearsContainer = styled.div`
  ${tw`
    grid
    w-64
    grid-cols-4
    gap-1
  `}
`;

export const Year = styled(Base)<YearProps>`
  ${tw`font-semibold`}

  ${({ $isBeside }) => $isBeside && tw`bg-white text-gray-500 hover:(bg-white text-gray-500)`};
`;

export const DecadesContainer = YearsContainer;

export const Decade = Year;

export const PickerContainer = styled.div`
  ${tw`
    absolute
    z-50
    block
    mt-1
  `}
`;

export const PickerSubContainer = styled.div`
  ${tw`
    items-center
    bg-white
    p-4
    rounded-t
    rounded-b-lg
    shadow-md-over
  `}
`;

export const PickerButtonsContainer = styled.div`
  ${tw`
    flex
    justify-between
    items-center
    mb-4
  `}
`;

export const MonthSelector = styled.a`
  ${tw`
    font-semibold
    font-heading
    text-blue-700
    capitalize
    no-underline
    cursor-pointer
  `}
`;

export const InputTextIconRightWrapper = styled.div`
  ${tw`
    absolute
    top-[calc(50%-12px)]
    right-4
    text-primary
    z-10
  `}
`;

export const TodayButton = styled.button.attrs({ type: 'button' })`
  ${tw`link-primary bg-transparent text-sm font-semibold`}
`;
