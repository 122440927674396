import React from 'react';
import tw, { styled } from 'twin.macro';

import { Skeleton, Tr } from '@new-components';

const SkeletonHit = () => (
  <Tr>
    <td tw="w-12 max-w-12 p-1.5">
      <Skeleton tw="w-4 h-4" />
    </td>
    <td tw="w-36 max-w-36">
      <Skeleton />
    </td>
    <td tw="w-36 max-w-36">
      <Skeleton />
    </td>
    <td tw="w-28 max-w-28">
      <Skeleton />
    </td>
    <td tw="w-28 max-w-28">
      <Skeleton />
    </td>
    <td tw="w-44 max-w-44">
      <Skeleton />
    </td>
    <td tw="w-24 max-w-24">
      <Skeleton />
    </td>
    <td tw="w-24 max-w-24">
      <Skeleton />
    </td>
    <td tw="w-24 max-w-24">
      <Skeleton />
    </td>
  </Tr>
);

export default SkeletonHit;
