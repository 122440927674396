import React, { useState, useEffect, useRef } from 'react';
import tw from 'twin.macro';
import Badge from '../Badge';

const StyledResponsiveContainer = tw.div`
  flex items-center
  w-full
`;

const StyledResponsiveWrapper = tw.div`
  flex-1 inline-block overflow-hidden whitespace-nowrap w-full

  [> div]:not-last:mr-1
  [> span]:not-last:after:content-[', ']
`;

export type Props = React.ComponentProps<'div'> & {
  children: any;
  overflow?: 'truncate' | 'hidden';
};

const MODULO = 2;

const ResponsiveWrapper = ({ children, overflow = 'truncate', ...props }: Props) => {
  const childs = React.Children.toArray(children);
  const [visibleChildrenCount, setVisibleChildrenCount] = useState(childs.length);
  const container = useRef<HTMLDivElement>(null);
  const wrapper = useRef<HTMLDivElement>(null);
  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (timeout.current) clearTimeout(timeout.current);

      timeout.current = setTimeout(() => {
        if (wrapper.current) {
          let visibleCount = 0;
          let totalWidth = 0;

          const maxWidth = wrapper.current.clientWidth;
          const { childNodes } = wrapper.current;
          const nbNodes = childNodes.length;

          childNodes.forEach(el => {
            if (el instanceof HTMLElement) {
              const node = el;

              const remainingWidth = maxWidth - totalWidth;

              if (
                overflow === 'hidden' &&
                nbNodes > 1 &&
                node.offsetWidth > remainingWidth / MODULO
              )
                node.style.maxWidth = `${remainingWidth / MODULO}px`;

              totalWidth += node.offsetWidth;
              if (totalWidth < maxWidth + 1) {
                visibleCount += 1;
                if (overflow === 'hidden') node.style.visibility = 'visible';
              } else if (overflow === 'hidden') {
                node.style.visibility = 'hidden';
              }
            }
          });

          if (overflow === 'truncate') visibleCount += 1;
          setVisibleChildrenCount(visibleCount || 1);
        }
      }, 100);
    });

    if (container.current) observer.observe(container.current);

    return () => observer.disconnect();
  }, [children, overflow]);

  const totalChildren = React.Children.count(children);
  const hiddenChildrenCount = totalChildren - visibleChildrenCount;

  return (
    <StyledResponsiveContainer {...props} ref={container}>
      <StyledResponsiveWrapper ref={wrapper} css={[overflow === 'truncate' && tw`truncate`]}>
        {children}
      </StyledResponsiveWrapper>
      {hiddenChildrenCount > 0 && (
        <Badge color="dark-blue-2" shape="oval" tw="ml-1">
          +{hiddenChildrenCount}
        </Badge>
      )}
    </StyledResponsiveContainer>
  );
};

export default ResponsiveWrapper;
