import React, { useMemo } from 'react';
import { Text as StyledText } from './styled-components';

export default function Text({
  align = 'left',
  children,
  variant = 'm',
  bold,
  color,
  secondary,
  title,
  underline,
  className,
  italic,
}: TextProps) {
  const tag = useMemo(() => {
    if (variant.startsWith('h')) {
      return variant;
    }
    return 'span';
  }, [variant]);

  return (
    <StyledText
      className={className}
      align={align}
      as={tag}
      bold={bold}
      color={color}
      secondary={secondary}
      title={title}
      underline={underline}
      italic={italic}
      variant={variant}>
      {children}
    </StyledText>
  );
}
