import { createGlobalStyle } from 'styled-components';
import tw from 'twin.macro';

const Toastify = createGlobalStyle`
  .Toastify__toast-container {
    ${tw`fixed z-50 top-4 right-4`};
    ${tw`p-2 w-auto max-w-[600px] box-border`}
  }

  @media only screen and (max-width : 480px) {
    .Toastify__toast-container {
      ${tw`w-full max-w-full p-0 top-0 left-0 m-0`}
      ${tw`translate-x-0`}
    }
  }

  .Toastify__toast {
    ${tw`relative min-h-0 box-border mb-4 p-2 rounded-lg`}
    ${tw`flex gap-4 justify-between items-center overflow-hidden`}
    ${tw`font-body text-sm text-primary`}
    ${tw`cursor-default`}
    ${tw`z-0`}
    ${tw`bg-blue-005 border border-blue-100 shadow-sm-over`}
  }

  .Toastify__toast--close-on-click {
    ${tw`cursor-pointer`}
  }

  .Toastify__toast-body {
    ${tw`flex flex-1 items-center m-0 p-0`}
  }

  .Toastify__toast-body > div:last-child {
    ${tw`flex-1 break-words`}
  }

  .Toastify--animate {
    animation-fill-mode: both;
    animation-duration: 0.7s;
  }

  @media only screen and (max-width : 480px) {
    .Toastify__toast {
      ${tw`mb-0 rounded-none`}
    }
  }

  .Toastify__toast.success {
    ${tw`bg-green-005 border-green-500`}
  }
  .Toastify__toast.warning {
    ${tw`bg-orange-005 border-orange-200`}
  }
  .Toastify__toast.error {
    ${tw`bg-red-50 border-red-500`}
  }

  .Toastify__close-button {
    ${tw`self-center cursor-pointer`}
    ${tw`text-primary bg-transparent opacity-70`}
    ${tw`outline-none border-none`}
    ${tw`p-0`}
    ${tw`[>svg]:(fill-current h-4 w-4)`}
    ${tw`[:hover,:focus]:(opacity-90)`}
  }

  @keyframes Toastify__bounceInRight {
    from, 60%, 75%, 90%, to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    from {
      opacity: 0;
      transform: translate3d(3000px, 0, 0);
    }
    60% {
      opacity: 1;
      transform: translate3d(-25px, 0, 0);
    }
    75% {
      transform: translate3d(10px, 0, 0);
    }
    90% {
      transform: translate3d(-5px, 0, 0);
    }
    to {
      transform: none;
    }
  }
  @keyframes Toastify__bounceOutRight {
    20% {
      opacity: 1;
      transform: translate3d(-20px, 0, 0);
    }
    to {
      opacity: 0;
      transform: translate3d(2000px, 0, 0);
    }
  }

  .Toastify__bounce-enter--top-right {
    animation-name: Toastify__bounceInRight;
  }
  .Toastify__bounce-exit--top-right {
    animation-name: Toastify__bounceOutRight;
  }

  @keyframes Toastify__slideInRight {
    from {
      transform: translate3d(110%, 0, 0);
      visibility: visible;
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes Toastify__slideOutRight {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      visibility: hidden;
      transform: translate3d(110%, 0, 0);
    }
  }

  .Toastify__slide-enter--top-right {
    animation-name: Toastify__slideInRight;
  }

  .Toastify__slide-exit--top-right {
    animation-name: Toastify__slideOutRight;
  }

  @keyframes Toastify__trackProgress {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }

  .Toastify__progress-bar--animated {
    animation: Toastify__trackProgress linear 1 forwards;
  }
`;

export default Toastify;
