import React, { PropsWithChildren, useState } from 'react';
import tw, { styled, theme } from 'twin.macro';
import { NavArrowDown, Xmark } from 'iconoir-react';

export type Props = PropsWithChildren<{
  title: string;
  onClose?: () => void;
}>;

export type ItemProps = React.ComponentProps<'div'> & {
  className?: string | string[];
  canOpen?: boolean;
};

export type ItemTitleProps = PropsWithChildren<{
  rightEl?: React.ReactNode;
}>;

const StyledSideBarContainer = styled.div`
  ${tw`w-72 h-full`}
  ${tw`bg-blue-005`}
  background: radial-gradient(
    circle at bottom -10rem left -7rem,
    ${theme`colors.blue.010`} 30%,
    ${theme`colors.blue.005`} 30%
  );
`;

const StyledTitle = styled.div`
  ${tw`flex justify-between items-center`}
  ${tw`py-3.5 pl-8 pr-6`}
  ${tw`border-b border-blueGray-100`}
  ${tw`text-sm font-semibold`}
`;

const CloseButton = styled.button`
  ${tw`border-none bg-transparent cursor-pointer`}
`;

const SideBar = ({ children, title, onClose, ...props }: Props) => (
  <StyledSideBarContainer {...props}>
    <StyledTitle>
      {title}
      {onClose && (
        <CloseButton type="button" onClick={onClose}>
          <Xmark />
        </CloseButton>
      )}
    </StyledTitle>
    <div>{children}</div>
  </StyledSideBarContainer>
);

const ItemContainer = styled.div<{ $isOpen?: boolean }>`
  ${tw`pl-8 pr-6`}
  ${({ $isOpen }) => ($isOpen ? tw`bg-blue-010` : tw`bg-transparent`)}
`;

const ItemTitleContainer = styled.div<{ $isOpen?: boolean; $canOpen?: boolean }>`
  ${tw`cursor-pointer`}
  ${tw`flex items-center justify-between gap-4`}
  ${tw`w-full py-3.5`}
  ${tw`text-sm`}
  ${tw`bg-transparent`}
  ${({ $isOpen }) => $isOpen && tw`font-semibold`}
  ${({ $canOpen }) => !$canOpen && tw`opacity-50 cursor-not-allowed`}
`;

const ItemContentWrapper = styled.div<{ $isOpen: boolean }>`
  ${tw`text-xs hidden`}
  ${({ $isOpen }) => $isOpen && tw`block`}
`;

export const ItemTitle = ({ children, rightEl, ...props }: ItemTitleProps) => (
  <ItemTitleContainer {...props}>
    <div tw="flex-1 flex items-center gap-2 truncate">{children}</div>
    {rightEl}
  </ItemTitleContainer>
);

export const Item = ({ children, className, canOpen = true }: ItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, ...rest] = React.Children.toArray(children);

  if (!React.isValidElement(title) || title.type !== ItemTitle) {
    throw new Error('First child of SideBar.Item must be a SideBar.Item.Title');
  }

  return (
    <ItemContainer $isOpen={isOpen && canOpen} className={className}>
      {React.cloneElement(title as React.ReactElement, {
        rightEl: rest.length > 0 ? <NavArrowDown tw="w-5 h-5" /> : title.props.rightEl,
        onClick: rest.length > 0 ? () => setIsOpen(!isOpen) : title.props.onClick,
        $isOpen: isOpen && canOpen,
        $canOpen: canOpen,
      })}
      {rest.length > 0 && (
        <ItemContentWrapper $isOpen={isOpen && canOpen}>
          <div tw="pt-1 pb-4">{rest}</div>
        </ItemContentWrapper>
      )}
      <hr />
    </ItemContainer>
  );
};

Item.Title = ItemTitle;
SideBar.Item = Item;

export default SideBar;
