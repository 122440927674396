import React from 'react';
import { addYears, format, isAfter, isBefore, setYear, getYear, getDecade } from 'date-fns';
import { Decade, DecadesContainer } from './styles';

interface DecadesProps {
  displayedDate: Date;
  startDate: Date | null;
  endDate: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  onClickDate: (date: Date) => void;
}

const Decades = ({
  displayedDate,
  startDate,
  endDate,
  minDate,
  maxDate,
  onClickDate,
}: DecadesProps) => {
  const today = new Date();

  return (
    <DecadesContainer>
      {[...Array(12).keys()].map(index => {
        const first = getDecade(displayedDate);
        const year = new Date(first - 10 + index * 10, 0, 1);

        const isCurrent = getDecade(year) === getDecade(today);

        const isSelected =
          (startDate && getDecade(year) === getDecade(startDate)) ||
          (endDate && getDecade(year) === getDecade(endDate)) ||
          false;
        const isInRange =
          (startDate &&
            endDate &&
            isAfter(year, startDate) &&
            isBefore(addYears(year, 11), endDate)) ||
          false;
        const isDisabled =
          (minDate && isBefore(year, minDate)) || (maxDate && isAfter(year, maxDate)) || false;

        return (
          <Decade
            key={index}
            $isCurrent={isCurrent}
            $isSelected={isSelected}
            $isInRange={isInRange}
            $isBeside={index === 0 || index === 11}
            $isDisabled={isDisabled}
            onClick={() => !isDisabled && onClickDate(setYear(displayedDate, getYear(year)))}>
            {format(year, 'yyyy')}
          </Decade>
        );
      })}
    </DecadesContainer>
  );
};

export default Decades;
