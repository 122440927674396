import React, { useMemo } from 'react';
import { startOfMonth, endOfMonth, startOfDay, endOfDay, subMonths } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useRange, UseRangeProps } from 'react-instantsearch';
import { capitalize } from '@lodash';
import 'twin.macro';

import { DatePicker, DatePickerProps, DateRange as DateRangeType, Select } from '@new-components';
import { msToSeconds } from 'src/helpers/number';

type Props = UseRangeProps & Omit<DatePickerProps, 'max' | 'min' | 'value'>;
type Option = { value: DateRangeType; label: string };

// TODO: Remove `max = Number.MAX_SAFE_INTEGER` when useRange hook is fixed: https://github.com/algolia/instantsearch/issues/5511
const DateRange = ({ max = Number.MAX_SAFE_INTEGER, ...props }: Props) => {
  const { t } = useTranslation();
  const { start: range, refine } = useRange({ max, ...props });

  const months = useMemo(() => {
    const date = startOfMonth(new Date());

    return [0, 1, 2, 3, 4].map(i => {
      const d = subMonths(date, i);

      return {
        value: [d, endOfMonth(d)],
        label: capitalize(t('{{date, month}}', { date: d })),
      };
    });
  }, [t]);

  const onChange = (date: DateRangeType) => {
    const bop = msToSeconds(date[0]?.getTime());
    const eop = msToSeconds(date[1]?.getTime());

    refine([bop, eop]);
  };

  const value = [
    range[0] && Number.isFinite(range[0]) ? startOfDay(range[0] * 1000) : null,
    range[1] && Number.isFinite(range[1]) && range[1] < Number.MAX_SAFE_INTEGER
      ? endOfDay(range[1] * 1000)
      : null,
  ];

  const [start, end] = value;

  const month =
    months.find(
      m => m.value[0].getTime() === start?.getTime() && m.value[1].getTime() === end?.getTime(),
    ) || null;

  return (
    <div tw="flex flex-col gap-2 items-center">
      <Select
        options={months}
        value={month}
        onChange={m => onChange((m as Option).value)}
        placeholder={t('globals.select')}
      />
      <span tw="text-sm">{t('globals.or')}</span>
      <div tw="w-full flex flex-col gap-1">
        <DatePicker
          placeholder={t('globals.date_picker.filter.at_gteq')}
          value={start}
          max={end}
          onChangeValue={v => onChange([v as Date, end])}
          onClickClear={() => onChange([null, end])}
        />
        <DatePicker
          placeholder={t('globals.date_picker.filter.at_lteq')}
          value={end}
          min={start}
          max={max ? endOfDay(max * 1000) : null}
          onChangeValue={v => onChange([start, endOfDay(v as Date)])}
          onClickClear={() => onChange([start, null])}
        />
      </div>
    </div>
  );
};

export default DateRange;
