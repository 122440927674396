import React from 'react';
import tw, { styled } from 'twin.macro';

export type Props = React.ComponentProps<'div'> & {
  imageSrc?: string;
};

const StyledWrapper = styled.div`
  ${tw`
    flex
    flex-1
    flex-col
    justify-center
    items-center
    gap-y-4
    my-8 md:mt-36
  `}
`;

const TableStateMessage = ({ imageSrc, children, ...props }: Props) => {
  return (
    <StyledWrapper {...props}>
      <img src={imageSrc} alt="Empty" loading="lazy" tw="py-6 min-h-[220px]" />
      <div tw="mt-4">{children}</div>
    </StyledWrapper>
  );
};

export default TableStateMessage;
