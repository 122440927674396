import React from 'react';
import { addDays, isSameDay, startOfMonth } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { dayOfTheWeekOf } from '@helpers/date';
import { Day, DaysContainer, WeekDay, WeekDaysContainer } from './styles';

interface DaysProps {
  isRange: boolean;
  displayedDate: Date;
  startDate: Date | null;
  endDate: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  onClickDate: (date: Date) => void;
}

const Days = ({
  isRange,
  displayedDate,
  startDate,
  endDate,
  minDate,
  maxDate,
  onClickDate,
}: DaysProps) => {
  const { t } = useTranslation();
  const weekDays: string[] = [
    t('globals.week_days.abbr.monday'),
    t('globals.week_days.abbr.tuesday'),
    t('globals.week_days.abbr.wednesday'),
    t('globals.week_days.abbr.thursday'),
    t('globals.week_days.abbr.friday'),
    t('globals.week_days.abbr.saturday'),
    t('globals.week_days.abbr.sunday'),
  ];

  const today = new Date();
  const firstOfMonth = startOfMonth(displayedDate);
  const start = dayOfTheWeekOf(firstOfMonth, 1, 1);

  return (
    <>
      <WeekDaysContainer>
        {weekDays.map(day => (
          <WeekDay key={day}>{day}</WeekDay>
        ))}
      </WeekDaysContainer>
      <DaysContainer>
        {[...Array(42).keys()].map(index => {
          const current = addDays(start, index);
          const currentDate = new Date(current);
          const day = currentDate.getDate();
          const month = current.getMonth();
          const year = current.getFullYear();

          let isSelected = false;
          let isInRange = false;

          const isCurrent = isSameDay(currentDate, today);

          if (isRange) {
            if (startDate && !endDate) {
              isSelected = isSameDay(currentDate, startDate);
            } else if (endDate && startDate) {
              isSelected = isSameDay(currentDate, startDate) || isSameDay(currentDate, endDate);
              isInRange = currentDate > startDate && currentDate < endDate;
            }
          } else if (startDate) {
            isSelected = isSameDay(currentDate, startDate);
          }

          const isDisabled =
            (minDate && currentDate < minDate) || (maxDate && currentDate > maxDate);

          const isCurrentMonthAndYear =
            month === displayedDate.getMonth() && year === displayedDate.getFullYear();

          if (index > 6 && !isCurrentMonthAndYear) return null;

          return (
            <Day
              key={index}
              $isCurrent={isCurrent}
              $isInRange={isInRange}
              $isSelected={isSelected}
              $isDisabled={isDisabled || false}
              $isCurrentMonthAndYear={isCurrentMonthAndYear}
              onClick={() => !isDisabled && onClickDate(new Date(current))}>
              {day}
            </Day>
          );
        })}
      </DaysContainer>
    </>
  );
};

export default Days;
