import React from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { useTranslation } from 'react-i18next';

import { Euro } from 'iconoir-react';

import { SideBar, Badge } from '@new-components';
import { NumberRange } from '@new-components/InstantSearch';

const Amount = () => {
  const { t } = useTranslation();
  const { indexUiState, indexRenderState } = useInstantSearch();

  // TODO: Remove check on `Number.MAX_SAFE_INTEGER` when useRange hook is fixed: https://github.com/algolia/instantsearch/issues/5511
  const length =
    indexUiState.range?.date && indexUiState.range.amount !== `:${Number.MAX_SAFE_INTEGER}` ? 1 : 0;
  const canRefine = indexRenderState.range?.amount?.canRefine || false;

  return (
    <SideBar.Item canOpen={canRefine}>
      <SideBar.Item.Title>
        {t('expenses.list.filters.amount')}
        {length > 0 && (
          <Badge color="yellow" shape="rounded">
            {length}
          </Badge>
        )}
      </SideBar.Item.Title>
      <NumberRange id="filter-amount" attribute="amount" rightEl={<Euro />} multiplier={100} />
    </SideBar.Item>
  );
};

export default Amount;
