import React from 'react';
import { isSameYear, format, isAfter, isBefore, setYear, getYear } from 'date-fns';
import { startOfYearPeriod } from '@helpers/date';
import { Year, YearsContainer } from './styles';

interface YearsProps {
  displayedDate: Date;
  startDate: Date | null;
  endDate: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  onClickDate: (date: Date) => void;
}

const Years = ({
  displayedDate,
  startDate,
  endDate,
  minDate,
  maxDate,
  onClickDate,
}: YearsProps) => {
  const today = new Date();

  return (
    <YearsContainer>
      {[...Array(12).keys()].map(index => {
        const first = startOfYearPeriod(displayedDate, 10);
        const year = new Date(first - 1 + index * 1, 0, 1);

        const isCurrent = isSameYear(year, today);
        const isSelected =
          (startDate && isSameYear(year, startDate)) ||
          (endDate && isSameYear(year, endDate)) ||
          false;
        const isInRange =
          (startDate && endDate && isAfter(year, startDate) && isBefore(year, endDate)) || false;
        const isDisabled =
          (minDate && isBefore(year, minDate)) || (maxDate && isAfter(year, maxDate)) || false;

        return (
          <Year
            key={index}
            $isCurrent={isCurrent}
            $isBeside={index === 0 || index === 11}
            $isDisabled={isDisabled}
            $isSelected={isSelected}
            $isInRange={isInRange}
            onClick={() => !isDisabled && onClickDate(setYear(displayedDate, getYear(year)))}>
            {format(year, 'yyyy')}
          </Year>
        );
      })}
    </YearsContainer>
  );
};

export default Years;
