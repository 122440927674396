import React from 'react';
import tw, { styled } from 'twin.macro';

export type Props = React.ComponentPropsWithoutRef<'input'> & {
  as?: React.ElementType;
  icon?: React.ReactElement;
};

const RadioButtonContainer = styled.label`
  ${tw`inline-block align-middle relative cursor-pointer`}
`;

const CheckedIcon = styled.span`
  ${tw`hidden absolute top-[-2px] left-[-2px] w-5 h-5`}
  ${tw`items-center justify-center`}
`;

const Checked = styled.span`
  ${tw`hidden absolute top-[1px] left-[1px] w-3.5 h-3.5`}
  ${tw`border-2 border-white rounded-full bg-blue-500`}
`;

const StyledRadioButton = styled.span`
  ${tw`block w-4.5 h-4.5 relative`}
  ${tw`border rounded-full border-blueGray-100 hover:border-blue-050`}

  input:checked + & > ${CheckedIcon},
  input:checked + & > ${Checked} {
    ${tw`flex`}
  }

  input:focus + & {
    ${tw`border-blue-050`}
  }

  input:disabled + & {
    ${tw`border-gray-300 bg-blueGray-050 text-gray-300 cursor-not-allowed`}
  }
`;

const HiddenRadioButton = styled.input.attrs({ type: 'radio' })`
  ${tw`absolute w-0 h-0 opacity-0`}
`;

const RadioButton = ({ icon, as, ...props }: Props) => (
  <RadioButtonContainer as={as}>
    <HiddenRadioButton {...props} />
    <StyledRadioButton>{icon ? <CheckedIcon>{icon}</CheckedIcon> : <Checked />}</StyledRadioButton>
  </RadioButtonContainer>
);

export default RadioButton;
