import React, { ForwardedRef, forwardRef, useState, useEffect } from 'react';
import 'twin.macro';
import { addMonths, addYears, startOfDay, endOfDay, endOfMonth, startOfMonth } from 'date-fns';
import { NavArrowLeft, NavArrowRight } from 'iconoir-react';

import { t } from 'i18next';
import { startOfYearPeriod } from '@helpers/date';
import type { DateRange } from './index';

import Button from '../Button';
import Days from './Days';
import Months from './Months';
import Years from './Years';
import Decades from './Decades';

import {
  PickerButtonsContainer,
  PickerContainer,
  PickerSubContainer,
  MonthSelector,
  TodayButton,
} from './styles';
import Switch from '../Switch';

export type Modes = 'days' | 'months';
type Views = 'days' | 'months' | 'years' | 'decades';

type Props = {
  date?: Date | DateRange | null;
  setShow: (show: boolean) => void;
  onChangeDate?: (date: Date | DateRange) => void;
  maxDate?: Date | null;
  minDate?: Date | null;
  autoHide?: boolean;
  mode: Modes;
  setMode: (mode: Modes) => void;
};

const ArrowLeftIcon = <NavArrowLeft />;
const ArrowRightIcon = <NavArrowRight />;

const goToPrevNext = (view: Views, date: Date, direction: number): Date => {
  switch (view) {
    case 'days':
      return addMonths(date, direction);
    case 'months':
      return addYears(date, direction);
    case 'years':
      return addYears(date, direction * 10);
    case 'decades':
      return addYears(date, direction * 100);
    default:
      return addYears(date, direction * 10);
  }
};

const funcDayOrNull = (date: Date | null, func: (d: Date) => Date): Date | null =>
  date ? func(date) : null;

const Picker = forwardRef(
  (
    { date, setShow, onChangeDate, maxDate, minDate, autoHide = true, mode, setMode }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const today = startOfDay(new Date());
    const isRange = Array.isArray(date) && date.length === 2;
    const [startDate, setStartDate] = useState<Date | null>(
      funcDayOrNull(isRange ? date[0] : (date as Date), startOfDay),
    );
    const [endDate, setEndDate] = useState<Date | null>(
      funcDayOrNull(isRange ? date[1] : null, endOfDay),
    );
    const [displayedDate, setDisplayedDate] = useState<Date>(startDate || today);

    const [view, setView] = useState<Views>(mode);

    const calculateView = (): Views => {
      if (view === 'days') return 'months';
      if (view === 'months') return 'years';
      if (view === 'years') return 'decades';

      return view;
    };

    useEffect(() => {
      if (Array.isArray(date)) {
        setStartDate(funcDayOrNull(date[0], startOfDay));
        setEndDate(funcDayOrNull(date[1], endOfDay));
      } else if (date) {
        setStartDate(startOfDay(date));
      }
    }, [date]);

    const changeMode = (newMode: Modes) => {
      if (newMode === 'months' && view === 'days') setView('months');
      if (newMode === 'days' && view !== 'days') setView('days');
      setMode(newMode);
    };

    const changeSelectedDate = (action: 'prev' | 'next' | 'date' | 'today', newDate: Date) => {
      const hide = autoHide && view === 'days' && action === 'date';
      const newStartDate = mode === 'months' ? startOfMonth(newDate) : startOfDay(newDate);
      const newEndDate = mode === 'months' ? endOfMonth(newDate) : endOfDay(newDate);

      if (isRange) {
        if (startDate && !endDate) {
          if (newDate < startDate) {
            setEndDate(startDate);
            setStartDate(newStartDate);
            onChangeDate?.([newStartDate, startDate]);
          } else {
            setEndDate(newEndDate);
            onChangeDate?.([startDate, newEndDate]);
          }
          setShow(!hide);
        } else {
          setStartDate(newStartDate);
          setEndDate(null);
          onChangeDate?.([newStartDate, null]);
        }
      } else {
        onChangeDate?.(newStartDate);
        setShow(!hide);
      }
    };

    const yearPeriod = startOfYearPeriod(displayedDate, 10);

    return (
      <PickerContainer ref={ref} data-testid="date-picker-popup">
        <PickerSubContainer>
          {isRange && (
            <div tw="mb-4">
              <Switch tw="w-full">
                <Switch.Item
                  name="datepicker-mode"
                  value="days"
                  checked={mode === 'days'}
                  onChange={e => changeMode(e.target.value as Modes)}>
                  <span tw="uppercase">{t('globals.days')}</span>
                </Switch.Item>
                <Switch.Item
                  name="datepicker-mode"
                  value="months"
                  checked={mode === 'months'}
                  onChange={e => changeMode(e.target.value as Modes)}>
                  <span tw="uppercase">{t('globals.months')}</span>
                </Switch.Item>
              </Switch>
            </div>
          )}
          <PickerButtonsContainer>
            <MonthSelector onClick={() => setView(calculateView())}>
              {view === 'days' && t('{{date, month_year}}', { date: displayedDate })}
              {view === 'months' && t('{{date, yyyy}}', { date: displayedDate })}
              {view === 'years' && `${yearPeriod} - ${yearPeriod + 9}`}
              {view === 'decades' && `${yearPeriod} - ${yearPeriod + 90}`}
            </MonthSelector>

            <div tw="flex">
              <Button
                shade="ghost"
                onClick={() => setDisplayedDate(goToPrevNext(view, displayedDate, -1))}
                rightIcon={ArrowLeftIcon}
              />

              <Button
                shade="ghost"
                onClick={() => setDisplayedDate(goToPrevNext(view, displayedDate, 1))}
                rightIcon={ArrowRightIcon}
              />
            </div>
          </PickerButtonsContainer>
          <div tw="mb-4">
            {view === 'days' && (
              <Days
                isRange={isRange}
                displayedDate={displayedDate}
                startDate={startDate}
                endDate={endDate}
                minDate={minDate}
                maxDate={maxDate}
                onClickDate={d => changeSelectedDate('date', d)}
              />
            )}
            {view === 'months' && (
              <Months
                displayedDate={displayedDate}
                startDate={startDate}
                endDate={endDate}
                minDate={minDate}
                maxDate={maxDate}
                onClickDate={d => {
                  setDisplayedDate(d);
                  if (mode === 'days') setView('days');
                  else changeSelectedDate('date', startOfMonth(d));
                }}
              />
            )}
            {view === 'years' && (
              <Years
                displayedDate={displayedDate}
                startDate={startDate}
                endDate={endDate}
                minDate={minDate}
                maxDate={maxDate}
                onClickDate={d => {
                  setDisplayedDate(d);
                  setView('months');
                }}
              />
            )}
            {view === 'decades' && (
              <Decades
                displayedDate={displayedDate}
                startDate={startDate}
                endDate={endDate}
                minDate={minDate}
                maxDate={maxDate}
                onClickDate={d => {
                  setDisplayedDate(d);
                  setView('years');
                }}
              />
            )}
          </div>
          <TodayButton
            onClick={() => {
              setDisplayedDate(new Date());
              setView(mode);
            }}>
            {mode === 'months' ? t('globals.this_month') : t('globals.today')}
          </TodayButton>
        </PickerSubContainer>
      </PickerContainer>
    );
  },
);

Picker.displayName = 'Picker';

export default Picker;
