import React from 'react';
import 'twin.macro';

import { HitsPerPage, Pagination } from '@new-components/InstantSearch';

const hitsPerPage = [
  { value: 25, label: '25', default: true },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];

const TablePagination = () => (
  <div tw="flex justify-end  p-2 sm:(justify-between pr-10) border-t border-blueGray-100">
    <HitsPerPage items={hitsPerPage} tw="hidden sm:flex" />
    <Pagination />
  </div>
);

export default TablePagination;
