import React from 'react';

import { Tag } from '@new-components';
import { useTranslation } from 'react-i18next';

import Undefined from './Undefined';

const valueByType: Record<ExpenseSourceType, ExpenseHitType[]> = {
  card_expense: ['refund', 'preauth', 'personal', 'debit'],
  employee_expense: ['employee'],
  km_expense: ['km'],
  external_expense: ['external'],
  reversed_expense: ['reversed'],
  supplier_expense: ['supplier'],
};

type Props = {
  value: ExpenseHit['type'];
};

const TypeTag = ({ value }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'expenses.attributes.source_type',
  });

  const type = Object.keys(valueByType).find(k =>
    valueByType[k as ExpenseSourceType].includes(value as ExpenseHitType),
  );

  return type ? <Tag color="dark-blue">{t(type)}</Tag> : <Undefined>-</Undefined>;
};

export default TypeTag;
