import React from 'react';
import tw, { styled } from 'twin.macro';
import { EmptyStateMessage } from '@new-components';
import { useTranslation } from 'react-i18next';

const StyledMessage = styled.div`
  ${tw`text-sm text-center space-y-2`}
`;

const EmptyState = () => {
  const { t } = useTranslation();

  return (
    <EmptyStateMessage>
      <StyledMessage>
        <h6 tw="text-blue-500 block">{t('globals.search_empty_results')}</h6>
        <span tw="text-blueGray-700 block">{t('globals.search_change')}</span>
      </StyledMessage>
    </EmptyStateMessage>
  );
};

export default EmptyState;
