import { useLayoutEffect } from 'react';
import { useInstantSearch } from 'react-instantsearch';

import { transform } from '@lodash';
import { postCurrentAlgoliaSearch } from '@api';

const PostUiStateMiddleware = ({ indexName, indexId }: { indexName: string; indexId: string }) => {
  const { results, status, uiState } = useInstantSearch();

  const { configure, ...indexUiState } = uiState[indexName] || {};

  useLayoutEffect(() => {
    if (status === 'idle' && indexUiState) {
      const { nbHits, hitsPerPage, page } = results;

      // TODO: Remove transform when `useRange` hook is fixed: https://github.com/algolia/instantsearch/issues/5511
      const range = indexUiState.range
        ? transform(
            indexUiState.range,
            (acc, v, k: string) => {
              const value = v.replace(`:${Number.MAX_SAFE_INTEGER}`, '');
              if (value) acc[k] = value;
            },
            {} as Record<string, string>,
          )
        : undefined;

      const state = {
        indexId,
        ...indexUiState,
        range,
      };

      postCurrentAlgoliaSearch({
        ...configure,
        ...state,
        nbHits,
        hitsPerPage,
        page: page + 1,
      });
    }
  }, [indexId, status, configure, indexUiState, results]);

  return null;
};

export default PostUiStateMiddleware;
