import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHitsPerPage, HitsPerPageProps } from 'react-instantsearch';
import 'twin.macro';

import ListGroup from '../ListGroup';

type Props = HitsPerPageProps & React.ComponentProps<'div'>;

const HitsPerPage = ({ className, ...props }: Props) => {
  const { items, refine } = useHitsPerPage(props);
  const { t } = useTranslation();

  return (
    <div tw="flex items-center" className={className}>
      <ListGroup>
        {items.map(item => (
          <ListGroup.Item
            key={`hpp-${item.value}`}
            onChange={() => refine(item.value)}
            value={item.value}
            checked={item.isRefined}>
            {item.label}
          </ListGroup.Item>
        ))}
      </ListGroup>
      <span tw="pl-2 text-sm text-blueGray-700 font-normal">{t('globals.element_per_page')}</span>
    </div>
  );
};

export default HitsPerPage;
