import { DefaultTheme } from 'styled-components';

export const colors = {
  black: {
    primary: '#697484',
    secondary: '#D9DADD',
    tertiary: '#9996A2',
    quaternary: '#CFCED3',
    light: '#F4F4F5',
  },
  blue: {
    primary: '#00b0ff',
    secondary: '#0542BF',
    tertiary: '#129FF7',
    quaternary: '#68BAF9',
    quinary: '#46BBFF',
    senary: '#B5E0FC',
    light: '#cce5ff',
  },
  green: {
    primary: '#3EAE5A',
    secondary: '#02641E',
    tertiary: '#31c427',
    quaternary: '#4FCD48',
    quinary: '#24C26E',
    senary: '#38CC82',
    light: '#d4edda',
  },
  red: {
    primary: '#E45A5E',
    secondary: '#AE1000',
    tertiary: '#da2329',
    quaternary: '#DF4449',
    quinary: '#e45a5e',
    senary: '#F9E2E3',
    light: '#f5c6cb',
  },
  orange: {
    primary: '#FFB500',
    secondary: '#B76E00',
    tertiary: '#FFF6E9',
    quaternary: '#FFBF2F',
    quinary: '#ffb500',
    senary: '##FDF1DA',
    light: '#ffeeba',
  },
  white: {
    primary: '#FFFFFF',
  },
};

const theme: DefaultTheme = {
  components: {
    bottomScreenBarContainer: {
      background: colors.white.primary,
    },
    button: {
      error: {
        default: {
          background: colors.red.primary,
          border: colors.red.primary,
          color: colors.white.primary,
        },
        hover: {
          background: colors.red.secondary,
          border: colors.red.secondary,
          color: colors.white.primary,
        },
        disabled: {
          background: colors.black.quaternary,
          border: colors.black.quaternary,
          color: colors.white.primary,
        },
      },
      primary: {
        default: {
          background: colors.red.primary,
          border: colors.red.primary,
          color: colors.white.primary,
        },
        hover: {
          background: colors.red.secondary,
          border: colors.red.secondary,
          color: colors.white.primary,
        },
        disabled: {
          background: colors.black.quaternary,
          border: colors.black.quaternary,
          color: colors.white.primary,
        },
      },
      warning: {
        default: {
          background: colors.orange.primary,
          border: colors.orange.primary,
          color: colors.white.primary,
        },
        hover: {
          background: colors.orange.secondary,
          border: colors.orange.secondary,
          color: colors.white.primary,
        },
        disabled: {
          background: colors.black.quaternary,
          border: colors.black.quaternary,
          color: colors.white.primary,
        },
      },
      filter: {
        default: {
          background: colors.white.primary,
          border: colors.black.quaternary,
          color: colors.black.primary,
        },
        hover: {
          background: colors.white.primary,
          border: colors.black.quaternary,
          color: colors.red.primary,
        },
        disabled: {
          background: colors.black.quaternary,
          border: colors.black.quaternary,
          color: colors.white.primary,
        },
      },
      success: {
        default: {
          background: colors.green.primary,
          border: colors.green.primary,
          color: colors.white.primary,
        },
        hover: {
          background: colors.green.secondary,
          border: colors.green.secondary,
          color: colors.white.primary,
        },
        disabled: {
          background: colors.black.quaternary,
          border: colors.black.quaternary,
          color: colors.white.primary,
        },
      },
    },
    checkbox: {
      primary: {
        default: {
          background: colors.white.primary,
          border: colors.red.primary,
          color: colors.white.primary,
        },
        hover: {
          background: colors.red.secondary,
          border: colors.red.secondary,
          color: colors.white.primary,
        },
        disabled: {
          background: colors.black.quaternary,
          border: colors.black.tertiary,
          color: colors.black.tertiary,
        },
      },
      selected: {
        default: {
          background: colors.red.secondary,
          border: colors.red.secondary,
          color: colors.white.primary,
        },
        disabled: {
          background: colors.black.tertiary,
          border: colors.black.tertiary,
          color: colors.white.primary,
        },
      },
    },
    confirm: {
      default: {
        background: colors.white.primary,
      },
      error: {
        background: colors.red.primary,
      },
    },
    container: {
      default: {
        background: colors.white.primary,
        border: colors.red.primary,
      },
    },
    divider: {
      background: colors.black.secondary,
    },
    dropdown: {
      default: {
        background: colors.white.primary,
        border: colors.black.secondary,
      },
    },
    dropzone: {
      default: {
        background: colors.white.primary,
        border: colors.red.primary,
        color: colors.black.primary,
      },
      active: {
        background: colors.white.primary,
        border: colors.red.primary,
        color: colors.white.primary,
      },
      error: {
        background: colors.red.light,
        border: colors.red.primary,
        color: colors.red.primary,
      },
      success: {
        background: colors.green.primary,
        border: colors.green.primary,
        color: colors.white.primary,
      },
      disabled: {
        background: colors.black.quaternary,
        border: colors.black.tertiary,
        color: colors.white.primary,
      },
      hover: {
        border: colors.red.secondary,
        background: colors.white.primary,
        color: colors.white.primary,
      },
    },
    leftSidebarLayout: {
      default: {
        color: colors.white.primary,
      },
      hover: {
        color: colors.white.primary,
      },
    },
    filterColumn: {
      default: {
        border: colors.red.primary,
      },
    },
    filterContainer: {
      default: {
        background: colors.black.light,
      },
    },
    form: {
      default: {
        background: colors.white.primary,
      },
    },
    globalStyle: {
      primary: {
        background: colors.black.light,
        color: colors.black.primary,
      },
      info: {
        background: colors.blue.primary,
        color: colors.white.primary,
      },
      success: {
        background: colors.green.primary,
        color: colors.white.primary,
      },
      warning: {
        background: colors.orange.primary,
        color: colors.white.primary,
      },
      error: {
        background: colors.red.primary,
        color: colors.white.primary,
      },
    },
    globalStyleBoxShadow: {
      info: {
        background: 'rgba(0, 176, 255, 0.2)',
      },
      success: {
        background: 'rgba(62, 174, 90, 0.2)',
      },
      warning: {
        background: 'rgba(255, 181, 0, 0.2)',
      },
      error: {
        background: 'rgba(212, 75, 60, 0.2)',
      },
    },
    icon: {
      default: {
        background: colors.white.primary,
        color: colors.green.primary,
      },
      error: {
        background: colors.white.primary,
        color: colors.red.primary,
      },
      success: {
        background: colors.white.primary,
        color: colors.green.primary,
      },
    },
    iconHeaderContainer: {
      default: {
        color: colors.red.primary,
      },
    },
    inputText: {
      default: {
        background: colors.white.primary,
        border: colors.black.quaternary,
        color: colors.black.primary,
      },
      hover: {
        background: colors.white.primary,
        border: colors.red.secondary,
        color: colors.black.secondary,
      },
      focused: {
        background: colors.white.primary,
        border: colors.red.primary,
        color: colors.black.secondary,
      },
      filled: {
        default: {
          background: colors.white.primary,
          border: colors.black.quaternary,
          color: colors.black.primary,
        },
        focus: {
          background: colors.white.primary,
          border: colors.red.primary,
          color: colors.black.primary,
        },
      },
      error: {
        background: colors.white.primary,
        border: colors.red.primary,
        color: colors.red.primary,
      },
      disabled: {
        background: colors.black.quaternary,
        color: colors.black.secondary,
        border: colors.black.light,
      },
      withIcon: {
        default: {
          background: colors.black.secondary,
          border: colors.black.quaternary,
          color: colors.black.primary,
        },
        hover: {
          background: colors.black.quaternary,
          border: colors.black.quaternary,
          color: colors.black.primary,
        },
        focused: {
          background: colors.black.quaternary,
          border: colors.red.primary,
          color: colors.black.secondary,
        },
        error: {
          background: colors.red.light,
          border: colors.red.secondary,
          color: colors.black.secondary,
        },
      },
    },
    inputNumber: {
      default: {
        background: colors.white.primary,
        border: colors.black.quaternary,
        color: colors.black.primary,
      },
      hover: {
        background: colors.white.primary,
        border: colors.black.tertiary,
        color: colors.black.secondary,
      },
      focused: {
        background: colors.white.primary,
        border: colors.orange.primary,
        color: colors.black.secondary,
      },
      filled: {
        default: {
          background: colors.white.primary,
          border: colors.black.quaternary,
          color: colors.black.primary,
        },
        focus: {
          background: colors.white.primary,
          border: colors.orange.primary,
          color: colors.black.primary,
        },
      },
      error: {
        background: colors.white.primary,
        border: colors.red.primary,
        color: colors.red.primary,
      },
      disabled: {
        background: colors.black.quaternary,
        color: colors.black.secondary,
        border: colors.black.light,
      },
      withIcon: {
        default: {
          background: colors.black.secondary,
          border: colors.black.quaternary,
          color: colors.black.primary,
        },
        hover: {
          background: colors.black.quaternary,
          border: colors.black.quaternary,
          color: colors.black.primary,
        },
        focused: {
          background: colors.black.quaternary,
          border: colors.orange.primary,
          color: colors.black.secondary,
        },
        error: {
          background: colors.red.light,
          border: colors.red.secondary,
          color: colors.black.secondary,
        },
      },
    },
    label: {
      default: {
        background: colors.red.primary,
        color: colors.white.primary,
      },
      hover: {
        background: colors.red.secondary,
        color: colors.white.primary,
      },
    },
    link: {
      default: {
        background: colors.red.primary,
        border: colors.red.primary,
        color: colors.red.primary,
      },
      hover: {
        background: colors.red.secondary,
        border: colors.red.secondary,
        color: colors.red.secondary,
      },
      active: {
        background: colors.red.quaternary,
        border: colors.red.quaternary,
        color: colors.red.primary,
      },
    },
    linkContainer: {
      active: {
        background: colors.red.primary,
      },
    },
    loader: {
      primary: colors.red.primary,
      secondary: colors.red.secondary,
    },
    menuContainer: {
      responsive: {
        background: colors.red.primary,
        color: colors.white.primary,
      },
    },
    modalContainer: {
      default: {
        background: colors.white.primary,
      },
    },
    pageDescriptionContainer: {
      default: {
        background: colors.red.primary,
      },
      lighter: {
        background: colors.red.light,
      },
    },
    pagination: {
      default: {
        text: colors.black.primary,
        background: 'transparent',
        hoverText: colors.black.primary,
        hoverBackground: colors.red.primary,
      },
      active: {
        text: colors.red.primary,
        background: colors.black.quaternary,
        hoverText: colors.red.primary,
        hoverBackground: colors.black.quaternary,
      },
      disabled: {
        text: colors.black.quaternary,
        background: 'transparent',
        hoverText: colors.black.quaternary,
        hoverBackground: 'transparent',
      },
    },
    pill: {
      default: {
        background: colors.red.quaternary,
        border: colors.red.quaternary,
        color: colors.red.light,
      },
    },
    progressBar: {
      default: colors.black.quaternary,
      filled: colors.red.primary,
    },
    radioButton: {
      primary: {
        default: {
          background: colors.white.primary,
          border: colors.black.quaternary,
        },
        hover: {
          background: colors.red.secondary,
          border: colors.red.primary,
        },
        disabled: {
          background: colors.black.quaternary,
          border: colors.black.tertiary,
        },
      },
      selected: {
        default: {
          background: colors.red.primary,
          border: colors.red.primary,
        },
        disabled: {
          background: colors.black.tertiary,
          border: colors.black.tertiary,
        },
      },
    },
    select: {
      hover: {
        background: colors.red.secondary,
        border: colors.red.secondary,
        color: colors.white.primary,
      },
      focused: {
        background: colors.white.primary,
        border: colors.red.primary,
        color: colors.red.primary,
      },
      selected: {
        background: colors.red.primary,
        border: colors.red.primary,
        color: colors.white.primary,
      },
    },
    datepicker: {
      background: colors.white.primary,
      border: colors.black.quaternary,
      color: colors.black.primary,
      selectedDay: {
        background: colors.red.primary,
        color: colors.white.primary,
      },
      clear: {
        default: {
          background: colors.black.quaternary,
        },
        hover: {
          background: colors.red.primary,
        },
        active: {
          background: colors.red.secondary,
        },
      },
      header: {
        background: colors.black.light,
        border: colors.black.tertiary,
        monthColor: colors.black.primary,
        dayColor: colors.black.primary,
      },
    },
    selectCard: {
      hover: { border: colors.red.secondary },
      focused: { color: colors.red.primary, border: colors.red.primary },
      single: { color: colors.red.primary },
      selected: { background: colors.orange.primary, color: colors.white.primary },
    },
    sideBarContainer: {
      default: {
        background: colors.red.primary,
        color: colors.white.primary,
      },
      responsive: {
        background: colors.red.primary,
        color: colors.white.primary,
      },
    },
    status: {
      disabled: {
        background: colors.white.primary,
        color: colors.black.quaternary,
      },
      undefined: {
        background: colors.red.primary,
        color: colors.white.primary,
      },
      info: {
        background: colors.blue.primary,
        color: colors.white.primary,
      },
      success: {
        background: colors.green.primary,
        color: colors.white.primary,
      },
      warning: {
        background: colors.orange.primary,
        color: colors.white.primary,
      },
      error: {
        background: colors.red.primary,
        color: colors.white.primary,
      },
    },
    switchTable: {
      default: { border: colors.black.quaternary, color: colors.black.primary },
      active: {
        boder: colors.red.primary,
        color: colors.red.primary,
      },
      after: {
        boder: colors.red.primary,
      },
    },
    table: {
      head: {
        background: colors.black.secondary,
        border: colors.white.primary,
        color: colors.black.primary,
      },
      body: {
        background: colors.white.primary,
        border: colors.black.secondary,
        color: colors.black.primary,
      },
    },
    tableContainer: {
      empty: {
        background: colors.white.primary,
        border: colors.black.secondary,
      },
    },
    tags: {
      primary: {
        default: {
          background: colors.red.secondary,
          color: colors.red.primary,
        },
        hover: {
          background: colors.red.secondary,
          color: colors.red.primary,
        },
        disabled: {
          background: colors.black.quaternary,
          color: colors.black.tertiary,
        },
      },
      error: {
        default: {
          background: colors.red.tertiary,
          color: colors.red.primary,
        },
        hover: {
          background: colors.red.tertiary,
          color: colors.red.secondary,
        },
      },
      success: {
        default: {
          background: colors.green.light,
          color: colors.green.primary,
        },
        hover: {
          background: colors.green.tertiary,
          color: colors.green.secondary,
        },
      },
    },
    text: {
      primary: {
        color: colors.black.primary,
      },
      secondary: {
        color: colors.black.primary,
      },
      error: {
        color: colors.red.primary,
      },
      disabled: {
        color: colors.black.primary,
      },
      default: {
        color: colors.red.primary,
      },
    },
    topAlert: {
      default: { color: colors.white.primary },
      error: {
        content: {
          background: colors.red.primary,
          color: colors.white.primary,
        },
        href: {
          background: colors.white.primary,
          color: colors.red.primary,
        },
      },
      info: {
        content: {
          background: colors.blue.primary,
          color: colors.white.primary,
        },
        href: {
          background: colors.white.primary,
          color: colors.blue.primary,
        },
      },
      neutral: {
        content: {
          background: colors.white.primary,
          color: colors.black.tertiary,
        },
        href: {
          background: colors.orange.primary,
          color: colors.white.primary,
        },
      },
      warning: {
        content: {
          background: colors.orange.light,
          color: colors.orange.primary,
        },
        href: {
          background: colors.orange.primary,
          color: colors.orange.light,
        },
      },
    },
    tooltip: {
      default: {
        background: colors.black.secondary,
        color: colors.black.primary,
      },
    },
    widget: {
      background: colors.white.primary,
      border: colors.black.secondary,
      color: colors.red.primary,
    },
    wrapperIcon: {
      default: {
        color: colors.white.primary,
      },
    },
  },
  name: 'staging',
};
export default theme;
