import { createGlobalStyle } from 'styled-components';
import { globalStyles } from 'twin.macro';

// FIX: https://github.com/ben-rogerson/twin.macro/issues/773
const filteredGlobalStyles = Object.fromEntries(
  Object.entries(globalStyles).filter(
    k => k[0] !== "button, [type='button'], [type='reset'], [type='submit']",
  ),
);

const BaseStyles = createGlobalStyle(filteredGlobalStyles as any);

export default BaseStyles;
