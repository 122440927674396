import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRefinementList, RefinementListProps } from 'react-instantsearch';
import tw from 'twin.macro';

import SearchInput from '../SearchInput';
import Checkbox from '../Checkbox';

type ItemProps = {
  item: any;
  i18nKeyPrefix?: string;
};

export type Props = RefinementListProps & {
  Item?: React.ComponentType<ItemProps>;
  i18nKeyPrefix?: string;
};

const StyledContainer = tw.div`
  flex flex-col gap-2 items-start
`;

const StyledShowMore = tw.button`
  link-primary font-semibold
  bg-transparent border-none
`;

const cleanLabel = (label: string) => label.match(/^[0-9]+_(.*)$/)?.[1] || label;

const Default = ({ item, i18nKeyPrefix }: ItemProps) => {
  const { t } = useTranslation();

  const label =
    item.label !== 'undefined'
      ? t(cleanLabel(item.label), { keyPrefix: i18nKeyPrefix })
      : t('globals.undefined');

  return <span css={[item.isRefined && tw`font-semibold`]}>{label}</span>;
};

const RefinementList = ({
  searchable,
  limit = 5,
  Item = Default,
  i18nKeyPrefix,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const {
    items,
    refine,
    searchForItems,
    canToggleShowMore,
    isShowingMore,
    toggleShowMore,
    hasExhaustiveItems,
  } = useRefinementList({ limit, ...props });

  const [query, setQuery] = useState('');

  const onChangeQuery = (value: string) => {
    setQuery(value);
    searchForItems(value);
  };

  const isSearchable = searchable && (!!query || !hasExhaustiveItems || items.length > limit);

  return (
    <StyledContainer>
      {isSearchable && (
        <SearchInput
          shade="invert"
          size="sm"
          value={query}
          onChangeValue={onChangeQuery}
          placeholder={t('globals.search')}
        />
      )}
      <ul>
        {items.map(item => (
          <li key={item.label} tw="m-0">
            <label tw="flex p-1 gap-2 items-center">
              <Checkbox checked={item.isRefined} onChange={() => refine(item.value)} />
              <Item item={item} i18nKeyPrefix={i18nKeyPrefix} />
            </label>
          </li>
        ))}
      </ul>
      {canToggleShowMore && (
        <StyledShowMore type="button" onClick={toggleShowMore}>
          {isShowingMore ? t('globals.show_less') : t('globals.show_more')}
        </StyledShowMore>
      )}
    </StyledContainer>
  );
};

export default RefinementList;
