import { useQuery } from '@tanstack/react-query';
import { camelize } from '@helpers/object';
import { getAlgoliaSettings } from '../api';

export type AlgoliaSettings = {
  appId: string;
  appKey: string;
  indexName: string;
  displayAccountability: boolean;
  displayBillable: boolean;
  displayTags: boolean;
  displayBusinessCodes: boolean;
  displayLicensePlate: boolean;
  displayDriverCode: boolean;
  displayTabs: string[];
  displayBulkAnalyticCodes: boolean;
  displayBulkBusinessCodes: boolean;
};

const useAlgoliaSettings = () => {
  const { data, ...rest } = useQuery(['algolia_settings'], getAlgoliaSettings, {
    refetchOnMount: false,
  });

  return { ...rest, data: (data ? camelize(data) : data) as AlgoliaSettings };
};

export default useAlgoliaSettings;
