import styled from 'styled-components';

const fontSizes = {
  h1: '1.5rem',
  h2: '1.25rem',
  h3: '1.25rem',
  h4: '.875rem',
  l: '1rem',
  m: '.875rem',
  s: '.75rem',
};

export const Text = styled.div<TextProps>`
  color: ${props => {
    if (props.color) {
      return props.color;
    }
    if (props.secondary) {
      return props.theme.components.text.secondary.color;
    }

    return props.theme.components.text.primary.color;
  }};
  font-family: Roboto, Helvetica, sans-serif;
  font-size: ${props => (props.variant ? fontSizes[props.variant] : fontSizes.m)};
  font-style: ${props => (props.italic ? 'italic' : 'normal')};
  font-weight: ${props => {
    if (props.bold === true) {
      return '700';
    }
    if (props.bold === false) {
      return '400';
    }
    if (props.variant?.startsWith('h')) {
      return '700';
    }

    return '400';
  }};
  margin: 0;
  overflow: hidden;
  text-align: ${props => props.align};
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  text-overflow: ellipsis;
`;
