import { createGlobalStyle } from 'styled-components';
import tw, { theme } from 'twin.macro';

const CustomStyles = createGlobalStyle`
  body, #root {
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    ${tw`antialiased`}
    ${tw`bg-white`}
    ${tw`m-0`}
  }

  body {
    ${tw`h-screen`}
  }

  #root, #storybook-root {
    ${tw`font-body text-base text-blue-700`}
  }

  #root {
    ${tw`flex flex-col grow`}
    ${tw`border-t border-blueGray-100`}
  }

  h1, h2, h3, h4, h5, h6 {
    ${tw`font-heading`}
    ${tw`font-semibold`}
    ${tw`leading-tight`}
  }

  h1 {
    ${tw`text-5xl`}
  }

  h2 {
    ${tw`text-4xl`}
  }

  h3 {
    ${tw`text-2xl`}
  }

  h4 {
    ${tw`text-xl`}
  }

  h5 {
    ${tw`text-base`}
  }

  h6 {
    ${tw`text-sm`}
  }

  hr {
    ${tw`border-blueGray-100`}
  }

  a {
    ${tw`underline-offset-4`}
    ${tw`hover:(underline text-inherit)`} // Override .sass link hover color
    transition: color .3s ease;

    > svg { /* SVGs inside links: Icon */
      ${tw`w-[1.25em] h-[1.25em]`}
      ${tw`inline-block align-text-bottom`}
    }
  }

  label {
    &:has(+ input[required]),
    &:has(+ div > input[required]),
    & > span:has(+ input[required]:not([type="checkbox"]):not([type="radio"])),
    & > span:has(+ div > input[required]),
    &:has(+ textarea[required]),
    &:has(+ div > textarea[required]),
    & > span:has(+ textarea[required]),
    & > span:has(+ div > textarea[required]) {
      ${tw`after:(content-[" *"] text-red-500)`}
    }
  }

  input, textarea { /* reset style */
    ${tw`w-full border-0 focus:(outline-none ring-0)`}
  }

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  table {
    ${tw`w-full table-auto border-collapse`}
    ${tw`text-xs`}
  }

  th, td {
    ${tw`relative`}
    ${tw`pr-6 first:pl-4 last:(text-right pr-10)`}
    ${tw`whitespace-nowrap`}
  }

  th, thead td {
    ${tw`bg-white`}
    ${tw`py-2.5`}
    ${tw`font-semibold text-left`}
  }

  th {
    box-shadow: inset 0 1px 0 ${theme('colors.blueGray.100')},
                inset 0 -1px 0 ${theme('colors.blueGray.100')};
  }

  td {
    ${tw`py-3.5`}
    ${tw`border-b border-blueGray-100`}
    &[aria-label="Actions"] {
      text-align: -moz-right;
      text-align: -webkit-right;
    }
  }

  /* --- Work with legacy --- */

  html {
    font-family: Roboto, Arial, sans-serif;
  }

  @media screen and (min-width: 0px) {
    :root {
      ${tw`text-base`}
    }
  }

  .region__header a {
    ${tw`hover:no-underline`}
  }

  .header__logo img {
    ${tw`inline align-top`}
  }

  .regions > footer .container {
    ${tw`text-xs`}
  }

  .regions > footer a {
    ${tw`text-primary-2`}
  }

  .regions > footer svg {
    ${tw`inline`}
  }

  .header .button.notif .badge {
    ${tw`absolute`}
  }

  #banner-wrapper {
    ${tw`bg-white border-t border-blueGray-100`}
  }

  #banner {
    ${tw`mb-0`}
  }

  @media screen and (max-width: 600px) {
    .regions {
      ${tw`pt-16`}
    }
  }
}
`;

export default CustomStyles;
