import { apiClient } from '../constants';

export const getExpenseExportTemplate = (id?: string) =>
  apiClient.get(`/expense_export_templates/${id}.json`);

export const searchExpenseExportTemplates = (params?: ApiParams) =>
  apiClient.post<
    PaginatedSearchRequest<ExpenseExportTemplate>,
    PaginatedSearchRequest<ExpenseExportTemplate>
  >('/expense_export_templates/search', params);

export const getExpenseExportTemplatesSearch = () =>
  apiClient.get<
    PaginatedSearchRequest<ExpenseExportTemplate>,
    PaginatedSearchRequest<ExpenseExportTemplate>
  >('/expense_export_templates/search');

export const deleteExpenseExportTemplatesSearch = () =>
  apiClient.delete<
    PaginatedSearchRequest<ExpenseExportTemplate>,
    PaginatedSearchRequest<ExpenseExportTemplate>
  >('/expense_export_templates/search');

export const deleteExpenseExportTemplate = (id: ExpenseExportTemplate['id']) =>
  apiClient.delete(`/expense_export_templates/${id}.json`);

export const addExpenseExportTemplate = (params?: ExpenseExportTemplateForm) =>
  apiClient.post<ExpenseExportTemplate, ExpenseExportTemplate>('/expense_export_templates', params);

export const updateExpenseExportTemplate = (id: string, params: ExpenseExportTemplateForm) =>
  apiClient.patch<ExpenseExportTemplate, ExpenseExportTemplate>(
    `/expense_export_templates/${id}.json`,
    params,
  );

export const duplicateExpenseExportTemplate = (
  id: ExpenseExportTemplate['id'],
  name: ExpenseExportTemplate['name'],
) =>
  apiClient.post(`/expense_export_templates/${id}/duplicate.json`, {
    expense_export_template: { name },
  });

export const getExpenseExportTemplates = () =>
  apiClient
    .get<{ expense_export_templates: ExpenseExportTemplate[] }>('/expense_export_templates.json')
    .then(({ data }) => data.expense_export_templates);
