import { apiClient } from '../constants';

export const searchAnalyticCodes = (params?: ApiParams) =>
  apiClient.post<PaginatedSearchRequest<AnalyticCode>, PaginatedSearchRequest<AnalyticCode>>(
    '/analytic_codes/search',
    params,
  );
export const getAnalyticCodesSearch = () =>
  apiClient.get<PaginatedSearchRequest<AnalyticCode>, PaginatedSearchRequest<AnalyticCode>>(
    '/analytic_codes/search',
  );
export const deleteAnalyticCodesSearch = () =>
  apiClient.delete<PaginatedSearchRequest<AnalyticCode>, PaginatedSearchRequest<AnalyticCode>>(
    '/analytic_codes/search',
  );
export const deleteAnalyticCode = (id: AnalyticCode['id']) =>
  apiClient.delete(`/analytic_codes/${id}.json`);

export const archiveAnalyticCode = (id: AnalyticCode['id']) =>
  apiClient.post(`/analytic_codes/${id}/toggle_archive.json`);

export const addAnalyticCodes = (params?: AnalyticCodeForm) =>
  apiClient.post<AnalyticCode, AnalyticCode>('/analytic_codes', params);

export const importAnalyticCodesFile = (formData: FormData) => {
  return apiClient.post('/analytic_codes/import', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateAnalyticCode = (id: string, params: AnalyticCodeForm) =>
  apiClient.patch<AnalyticCode, AnalyticCode>(`/analytic_codes/${id}.json`, params);

export const getAnalyticCode = (id?: string) => apiClient.get(`/analytic_codes/${id}.json`);
