import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppWindow, Download, DownloadCircle, Edit, Table2Columns } from 'iconoir-react';

import tw, { styled } from 'twin.macro';

import type { Option } from '@new-components';

import { getCSRFToken } from '@constants/api-client';
import { Button, Card, Modal, ModalProps, BadgeIcon, Select } from '@new-components';
import { useGetExpenseExportTemplates, usePermissions } from '@hooks';

const OptionLabel = ({ name, creator_fullname }: { name: string; creator_fullname?: string }) => {
  const { t } = useTranslation();

  return (
    <>
      {name}
      {creator_fullname && (
        <span tw="text-blueGray-700 font-normal">
          {' '}
          - {t('globals.created_by', { fullname: creator_fullname })}
        </span>
      )}
    </>
  );
};

const OverviewLink = styled.a`
  ${tw`text-blueGray-700 flex justify-center underline cursor-pointer text-xs font-semibold`}
`;

const ExportExpenseModal = ({ isOpen, onClose }: ModalProps) => {
  const { t } = useTranslation();
  const { can } = usePermissions(['create_expense_export_template']);

  const [templateId, setTemplateId] = useState<string>();
  const [format, setFormat] = useState('xls_format');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { data } = useGetExpenseExportTemplates();

  const simplified = data?.find(
    ({ template_type }) =>
      template_type === 'mobility_simplified' || template_type === 'mooncard_simplified',
  );
  const complete = data?.find(({ template_type }) => template_type === 'complete');
  const options = data
    ?.filter(({ template_type }) => template_type === 'customized')
    .map(({ name, id, creator_fullname }) => ({
      label: <OptionLabel name={name} creator_fullname={creator_fullname} />,
      value: id,
    }));

  useEffect(() => {
    if (simplified) setTemplateId(simplified.id);
  }, [isOpen, simplified]);

  const handleTemplateChange = (newOption: Option) => {
    setTemplateId(newOption.value);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (!templateId || isSubmitted) {
      e.preventDefault();
      return;
    }
    setIsSubmitted(true);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form action="/expense_exports?restore_page=1" method="post" onSubmit={onSubmit}>
        <input type="hidden" name="authenticity_token" value={getCSRFToken()} />
        <input type="hidden" name="expense_export[expense_export_template_id]" value={templateId} />
        <Modal.Header>
          <div tw="flex flex-col items-center gap-2">
            <BadgeIcon color="blue" size="lg">
              <Download />
            </BadgeIcon>
            <h3 tw="text-blue-500">{t('expenses.export_expense_modal.title')}</h3>
            <p tw="text-base text-blueGray-700">{t('expenses.export_expense_modal.subtitle')}</p>
          </div>
        </Modal.Header>
        <hr tw="my-6" />
        <Modal.Body>
          <div tw="mb-6">
            <h4 tw="text-blue-500 mb-3">{t('expenses.export_expense_modal.export')}</h4>
            <div tw="flex flex-col sm:flex-row justify-center gap-4">
              <Card
                selected={!!templateId && templateId === simplified?.id}
                onClick={() => setTemplateId(simplified?.id)}
                tw="text-center">
                <Card.Icon>
                  <AppWindow />
                </Card.Icon>
                <h6 tw="text-blue-500">{t('expense_export_templates.name.mooncard_simplified')}</h6>
                <OverviewLink
                  href={`/expense_export_templates/${simplified?.id}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {t('expenses.export_expense_modal.overview')}
                </OverviewLink>
              </Card>
              <Card
                selected={!!templateId && templateId === complete?.id}
                onClick={() => setTemplateId(complete?.id)}
                tw="text-center">
                <Card.Icon>
                  <Table2Columns />
                </Card.Icon>
                <h6 tw="text-blue-500">{t('expense_export_templates.name.complete')}</h6>
                <OverviewLink
                  href={`/expense_export_templates/${complete?.id}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {t('expenses.export_expense_modal.overview')}
                </OverviewLink>
              </Card>
              {can('create_expense_export_template') && (
                <Card tw="text-center">
                  <a
                    href="/expense_export_templates/new"
                    target="_blank"
                    rel="noopener noreferrer"
                    tw="block hover:no-underline">
                    <Card.Icon>
                      <Edit />
                    </Card.Icon>
                    <h6 tw="text-blue-500">{t('expense_export_templates.new.title')}</h6>
                  </a>
                </Card>
              )}
            </div>
            {!!options?.length && (
              <div tw="my-4">
                <label htmlFor="select" tw="block mb-1 text-xs text-blueGray-700">
                  {t('expenses.export_expense_modal.select.title')}
                </label>
                <Select
                  options={options}
                  onChange={v => handleTemplateChange(v as Option)}
                  placeholder={t('expenses.export_expense_modal.select.placeholder')}
                  maxMenuHeight={100}
                />
              </div>
            )}
          </div>
          <div tw="mb-6">
            <h4 tw="text-blue-500 mb-2">{t('expenses.export_expense_modal.format')}</h4>
            <div tw="flex flex-row justify-center gap-4">
              <Card
                selected={format === 'xls_format'}
                onClick={() => setFormat('xls_format')}
                tw="text-center">
                <input
                  type="radio"
                  tw="hidden"
                  name="expense_export[type]"
                  value="xlsx"
                  checked={format === 'xls_format'}
                  readOnly
                />
                <h6 tw="text-blue-500">{t('expenses.export_expense_modal.formats.xls')}</h6>
              </Card>
              <Card
                selected={format === 'csv_format'}
                onClick={() => setFormat('csv_format')}
                tw="text-center">
                <input
                  type="radio"
                  tw="hidden"
                  name="expense_export[type]"
                  value="csv"
                  checked={format === 'csv_format'}
                  readOnly
                />
                <h6 tw="text-blue-500">{t('expenses.export_expense_modal.formats.csv')}</h6>
              </Card>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer tw="py-4 flex">
          <Button
            type="submit"
            rightIcon={<DownloadCircle />}
            tw="w-full justify-center"
            disabled={!templateId || isSubmitted}>
            {t('globals.export')}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ExportExpenseModal;
