import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import tw from 'twin.macro';

import type { AlgoliaSettings } from '@hooks';

import { useSelectedHits } from '@contexts/SelectedHits';
import { SideBar, StaticRefinements } from '@new-components';
import { SearchBox, Stats, CurrentRefinements } from '@new-components/InstantSearch';

import type { ExpensesPathTabs } from './types';

import BulkActions from './BulkActions';
import Filters from './Filters';
import SelectAllHits from './SelectAllHits';
import TablePagination from './TablePagination';

import DropdownExport from './DropdownExport';
import PostUiStateMiddleware from './PostUiStateMiddleware';

const refinementKeys: { [k: string]: { label: string; values: string[] } } = {
  card: { label: 'expenses.list.filters.source_type', values: ['expenses.tabs.card'] },
  external: { label: 'expenses.list.filters.source_type', values: ['expenses.tabs.external'] },
  km: { label: 'expenses.list.filters.source_type', values: ['expenses.tabs.km'] },
  mine: { label: 'expenses.list.filters.user_name', values: ['{{username}}'] },
  supplier: { label: 'expenses.list.filters.source_type', values: ['expenses.tabs.supplier'] },
  employee: { label: 'expenses.list.filters.source_type', values: ['expenses.tabs.employee'] },
};

const ExpensesSearch = ({
  indexId,
  settings,
}: {
  indexId: ExpensesPathTabs;
  settings: AlgoliaSettings;
}) => {
  const { t } = useTranslation();
  const { ids, selectMany } = useSelectedHits();
  const hasSelected = ids.length;

  const username = document.querySelector('body')?.dataset.fullname;
  const staticRefinements = refinementKeys[indexId as string];

  return (
    <>
      <PostUiStateMiddleware indexName={settings.indexName} indexId={indexId} />
      <div tw="flex flex-row items-center gap-2 pl-8 py-6 pr-10 border-b border-blueGray-100">
        <SearchBox placeholder={t('expenses.list.search_placeholder')} wait={500} />
        <DropdownExport />
      </div>

      <div tw="flex flex-1 w-full">
        <div tw="flex min-h-0">
          <SideBar
            tw="hidden md:block"
            title={hasSelected ? t('globals.bulk_action') : t('globals.filter_by')}
            onClose={hasSelected ? () => selectMany([]) : undefined}>
            <BulkActions css={!hasSelected && tw`hidden`} settings={settings} />
            <Filters
              indexId={indexId}
              displayAccountability={settings.displayAccountability}
              displayBillable={settings.displayBillable}
              displayBusinessCodes={settings.displayBusinessCodes}
              displayDriverCode={settings.displayDriverCode}
              displayTags={settings.displayTags}
              displayLicensePlate={settings.displayLicensePlate}
              css={hasSelected && tw`hidden`}
            />
          </SideBar>
        </div>
        <div tw="flex flex-col flex-1 min-w-0">
          <div tw="flex flex-wrap ml-4 my-3 mr-10 gap-2">
            <Stats i18nKey="expenses.list.results" />
            {staticRefinements && (
              <StaticRefinements
                items={[
                  {
                    label: t(staticRefinements.label),
                    values: staticRefinements.values.map((v: string) => t(v, { username })),
                  },
                ]}
              />
            )}
            <CurrentRefinements />
          </div>
          <SelectAllHits />
          <div tw="flex-1 overflow-auto">
            <Outlet />
          </div>
          <TablePagination />
        </div>
      </div>
    </>
  );
};

export default ExpensesSearch;
