import React from 'react';
import tw, { styled, theme } from 'twin.macro';
import { InfoCircle, Check, XmarkCircle, WarningTriangle } from 'iconoir-react';
import { useTranslation } from 'react-i18next';

export type Variant = 'info' | 'success' | 'error' | 'warning';

export type Props = React.PropsWithChildren<{
  variant: Variant;
}>;

const icons = {
  success: <Check color={theme('colors.green.500')} />,
  error: <XmarkCircle color={theme('colors.red.500')} />,
  warning: <WarningTriangle color={theme('colors.orange.200')} />,
  info: <InfoCircle color={theme('colors.blue.100')} />,
};

const StyledToast = styled.div`
  ${tw`flex gap-2 items-center`}
  ${tw`text-xs`}
`;

const StyledIconContainer = styled.div`
  ${tw`p-1 rounded-full bg-white [>svg]:(w-4 h-4)`}
`;

const Toast = ({ variant = 'info', children }: Props) => {
  const { t } = useTranslation();

  return (
    <StyledToast>
      <StyledIconContainer>{icons[variant]}</StyledIconContainer>
      <strong tw="font-semibold">{t(`globals.${variant}`)}</strong>
      <span tw="flex-1">{children}</span>
    </StyledToast>
  );
};

export default Toast;
