import React from 'react';
import { useTranslation } from 'react-i18next';
import { WarningTriangle } from 'iconoir-react';
import 'twin.macro';

import { Modal, ModalProps, Button, BadgeIcon } from '@new-components';

type Props = ModalProps & {
  onAccept: () => void;
};

const ConfirmBulkActionsModal = ({ isOpen, onClose, onAccept }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header>
        <BadgeIcon size="lg" color="red">
          <WarningTriangle />
        </BadgeIcon>
        <h3 tw="text-red-700 mt-2">{t('expenses.bulk.modal.title')}</h3>
        <p tw="text-gray-600">{t('expenses.bulk.modal.subtitle')}</p>
      </Modal.Header>
      <Modal.Footer tw="flex gap-4 py-4">
        <Button shade="secondary" onClick={onClose} tw="flex-1">
          {t('globals.cancel')}
        </Button>
        <Button tw="flex-1" onClick={onAccept}>
          {t('expenses.bulk.modal.submit')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmBulkActionsModal;
