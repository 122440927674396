import { Tooltip as ReactTooltip } from 'react-tooltip';
import styled from 'styled-components';

export const StyledTooltip = styled(ReactTooltip).attrs({
  classNameArrow: 'react-tooltip-arrow',
})`
  &&& {
    background-color: ${props => props.theme.components.tooltip.default.background};
    color: ${props => props.theme.components.tooltip.default.color};

    .react-tooltip-arrow {
      background: ${props => props.theme.components.tooltip.default.background};
    }
  }
`;

export const StyledAnchor = styled.div`
  color: ${props => props.theme.components.tooltip.default.background};
  &:hover {
    color: ${props => props.theme.components.button.primary.default.background};
  }
`;
