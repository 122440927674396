import React from 'react';

import BaseStyles from './base.styles';
import CustomStyles from './custom.styles';
import ToastifyStyles from './toastify.styles';

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
    <ToastifyStyles />
  </>
);

export default GlobalStyles;
