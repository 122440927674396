import React from 'react';
import tw from 'twin.macro';
import { useTranslation } from 'react-i18next';

import { useSelectedHits } from '@contexts/SelectedHits';
import { Tr, Td, Checkbox } from '@new-components';

import StepTag from '../StepTag';
import PaymentStepTag from '../PaymentStepTag';
import Data from '../TdData';
import TdAmount from '../TdAmount';
import Status from '../Status';

export type Props = {
  hit: ExpenseHit;
};

const Hit = ({ hit }: Props) => {
  const { t } = useTranslation();
  const { isSelected, toggle, all } = useSelectedHits();
  const isHitSelected = isSelected(hit) || all;

  const isIncoming = !hit.settled && hit.type === 'debit';

  return (
    <Tr isSelected={isHitSelected} css={isIncoming && tw`text-blueGray-800`}>
      <Td tw="w-12 max-w-12 p-1.5" aria-label="Select">
        <Checkbox
          as="span"
          checked={isHitSelected}
          readOnly
          onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
            e.stopPropagation();
            toggle(hit);
          }}
        />
      </Td>
      <Td tw="font-semibold w-36 max-w-36 truncate" href={`${hit.edit_link}?restore_page=1`}>
        {hit.user_name}
      </Td>
      <Td tw="w-36 max-w-36 truncate" href={`${hit.edit_link}?restore_page=1`}>
        <Data fallback={hit.merchant_name}>{hit.merchant_name}</Data>
      </Td>
      <Td tw="text-blueGray-800 w-28 max-w-28" href={`${hit.edit_link}?restore_page=1`}>
        {t('{{date, human}}', { date: new Date(hit.date * 1000) })}
      </Td>
      <Td tw="text-blueGray-800 w-28 max-w-28" href={`${hit.edit_link}?restore_page=1`}>
        {hit.due_at > 0 ? t('{{date, human}}', { date: new Date(hit.due_at * 1000) }) : '-'}
      </Td>
      <Td aria-label={hit.step} tw="w-44 max-w-44" href={`${hit.edit_link}?restore_page=1`}>
        <Status hit={hit} />
      </Td>
      <Td aria-label={hit.step} tw="w-24 max-w-24" href={`${hit.edit_link}?restore_page=1`}>
        <StepTag value={hit.step} />
      </Td>
      <Td tw="w-24 max-w-24 truncate" href={`${hit.edit_link}?restore_page=1`}>
        <PaymentStepTag value={hit.payment_status} />
      </Td>
      <TdAmount hit={hit} isIncoming={isIncoming} />
    </Tr>
  );
};

export default Hit;
