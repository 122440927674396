import React, { createContext, useContext, useMemo, useState, useCallback } from 'react';
import { Hit } from 'instantsearch.js';

type Index = {
  ids: string[];
  all: boolean;
};

type Type = {
  ids: string[];
  all: boolean;
  isSelected: (hit: Hit) => boolean;
  toggle: (hit: Hit) => boolean;
  selectMany: (ids: Hit[]) => void;
  selectAll: () => void;
};

const SelectedHitsContext = createContext({} as Type);

export const useSelectedHits = () => useContext(SelectedHitsContext);

const SelectHitsProvider = ({
  indexId,
  children,
}: React.PropsWithChildren<{ indexId: string }>) => {
  const [indexes, setIndexes] = useState<{ [index: string]: Index }>({});

  const isSelected = useCallback(
    (hit: Hit) =>
      indexes[indexId]
        ? indexes[indexId].all || indexes[indexId].ids.includes(hit.objectID)
        : false,
    [indexId, indexes],
  );

  const toggle = useCallback(
    (hit: Hit) => {
      if (indexes[indexId]?.ids.includes(hit.objectID)) {
        setIndexes({
          ...indexes,
          [indexId]: { ids: indexes[indexId].ids.filter(id => id !== hit.objectID), all: false },
        });
        return false;
      }

      setIndexes({
        ...indexes,
        [indexId]: { ids: [...(indexes[indexId]?.ids || []), hit.objectID], all: false },
      });

      return true;
    },
    [indexId, indexes],
  );

  const selectMany = useCallback(
    (hits: Hit[]) => {
      setIndexes({
        ...indexes,
        [indexId]: { ids: hits.map(({ objectID }) => objectID), all: false },
      });
    },
    [indexId, indexes],
  );

  const selectAll = useCallback(
    () =>
      setIndexes({
        ...indexes,
        [indexId]: { ids: indexes[indexId]?.ids || [], all: true },
      }),
    [indexId, indexes],
  );

  const value = useMemo(
    () => ({
      ids: indexes[indexId]?.ids || [],
      all: indexes[indexId]?.all || false,
      isSelected,
      toggle,
      selectMany,
      selectAll,
    }),
    [indexId, indexes, isSelected, toggle, selectMany, selectAll],
  );

  return <SelectedHitsContext.Provider value={value}>{children}</SelectedHitsContext.Provider>;
};

export default SelectHitsProvider;
